import { createSlice } from '@reduxjs/toolkit';
import { postItemStore } from '@Networking/APIs/Item/itemApi';
import { getItem } from '@Networking/APIs/Item/getitem';
import { toast } from 'react-toastify';

const ItemSlice = createSlice({
  name: 'ItemSlice',
  initialState: {
    loading: false,
    ItemData:[],
    itemDetail: {},
    success: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(postItemStore.pending, (state) => {
      // console.log("Pending....");
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postItemStore.fulfilled, (state, action) => {
      // console.log("Fulfilled....");
      state.loading = false;
      state.success = true;
      state.itemDetail = action.payload;
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
        console.log("this is error ");
      }
    });
    builder.addCase(postItemStore.rejected, (state, action) => {
      // console.log("Rejected....");
      state.loading = false;
      state.error = action.error.message || 'Login failed.';
    });
    builder.addCase(getItem.pending, state => {
      // console.log("Pending....");
      state.loading = true;
    });
    builder.addCase(getItem.fulfilled, (state, action) => {
        //  console.log("Fulfilled....");
      state.ItemData = action.payload;
      console.log(state.ItemData," state.Items")
    });
    builder.addCase(getItem.rejected, state => {
      // console.log("Rejected....");
      state.loading = false;
    });
  },
});

export default ItemSlice.reducer;