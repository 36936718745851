import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BASE_URL, countries, states , cities, countrycode, getuser} from '@Networking/APIs/NWConfig';
import Cookies from 'js-cookie';

// Fetch USer Detials 
export const fetchUserData = createAsyncThunk(
  'get/fetchUserData',
  async () => {  
   
    let url = BASE_URL + getuser; 
    const token = Cookies.get('authToken');
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      console.log("response ", response);
      return Promise.resolve(response);
    } catch (error) {
      console.log(error,"api error")
      return Promise.reject(error);
    }
  },
);
//Country Api
export const getcountries = createAsyncThunk(
    'get/getcountries',
    async () => {  
      console.log(countries, "url111");   
  
      let url = BASE_URL + countries; 
      console.log(url, "url");   
  
      const token = Cookies.get('authToken');
      console.log(token,"tokenn");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        console.log("response ", response);
        return Promise.resolve(response);
      } catch (error) {
        console.log(error,"api error")
        return Promise.reject(error);
      }
    },
  );
  
//State Api
export const getstate = createAsyncThunk(
    'get/getstate',
    async (countryId) => {  
      console.log(states, "url111");   
  
      let url = BASE_URL + states + countryId; 
      console.log(url, "url");   
  
      const token = Cookies.get('authToken');
      console.log(token,"tokenn");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        console.log("response ", response);
        return Promise.resolve(response);
      } catch (error) {
        console.log(error,"api error")
        return Promise.reject(error);
      }
    },
  );
  

//City Api
  export const getcity = createAsyncThunk(
    'get/getcity',
    async (stateId) => {  
      console.log(cities, "url111");   
        let url = BASE_URL + cities + stateId; 
      console.log(url, "url");   
  
      const token = Cookies.get('authToken');
      console.log(token,"tokenn");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        console.log("response ", response);
        return Promise.resolve(response);
      } catch (error) {
        console.log(error,"api error")
        return Promise.reject(error);
      }
    },
  );



  //country code
  export const getcountryCode = createAsyncThunk(
    'get/getcountryCode',
    async () => {  
      console.log(cities, "url111");   
        let url = BASE_URL + countrycode; 
      console.log(url, "url");   
  
      const token = Cookies.get('authToken');
      console.log(token,"tokenn");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        console.log("response ", response);
        return Promise.resolve(response);
      } catch (error) {
        console.log(error,"api error")
        return Promise.reject(error);
      }
    },
  );