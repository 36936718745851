import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getcity,
  getcountries,
  getstate,
} from "@Networking/APIs/Background/backgorundApi";
import { postCompanyStore } from "@Networking/APIs/Company/companyStoreApi";
import { getCompany } from "@Networking/APIs/Company/companyGetApi";

const CompanyForm = ({ onClose, onAddCompany }) => {
  const { country, state, city } = useSelector(
    (state) => state.BackgroundSlice || {}
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCompanyLogo(file);
    }
  };

  const onSubmit = async (data) => {
    if (validateForm()) {
      const newCompany = {
        company_name: data.companyName,
        company_email: data.companyEmail,
        company_contact: data.companyContact,
        company_short_name: data.companyShortName,
        company_address: data.companyAddress,
        company_country: selectedCountry
          ? selectedCountry
          : "",
        company_state: selectedState
          ? selectedState.label
          : "",
        company_city: selectedCity
          ? selectedCity.label
          : "",
        company_pincode: data.companyPincode,
        company_website: data.companyWebsite,
        company_gst: data.companyGst,
        company_logo: companyLogo, // Send the logo file
        terms_condition: data.termsCondition,
        facebook: data.facebook,
        twitter: data.twitter,
        instagram: data.instagram,
        linkedIn: data.linkedIn,
      };

      console.log(newCompany, " newCompany");

      try {
        // Assuming postCompanyStore returns a promise
        const response = await dispatch(postCompanyStore(newCompany));
        // Handle the response from the API
        if (response.payload.success) {
          // Store the company data to onAddCompany
          onAddCompany(newCompany);
          // Close the modal
          onClose();
          // Reset the form
          reset();
          // Optionally show a success message to the user
        } else {
          // Handle API errors
          console.error("Error in submitting company data:", response.error);
          // Show an error message to the user
        }
      } catch (error) {
        console.error("Error in submitting company data:", error);
        // Show an error message to the user
      }
    }
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption ? selectedOption.label : "");
    if (selectedOption) {
      dispatch(getstate(selectedOption.value));
    }
  };

  const handleStateChange = (newValue) => {
    setSelectedState(newValue);
    if (newValue) {
      dispatch(getcity(newValue.value));
    }
  };

  const handleCityChange = (newValue) => {
    setSelectedCity(newValue);
  };

  useEffect(() => {
    dispatch(getcountries());
  }, []);

  const validateForm = () => {
    const errors = {};

    // Client Validation
    if (!country) {
      errors.country = "Please select a country.";
    }

    //Payment Mode
    if (!state) {
      errors.state = "Please select a state.";
    }

    // Bank Validation
    if (!city) {
      errors.city = "Please select a city.";
    }

    return Object.keys(errors).length === 0;
  };

  return (
    <div
      className="modal fade show"
      style={{ display: "block", backdropFilter: "blur(5px)" }}
      aria-modal="true"
    >
      <div className="modal-dialog modal-lg">
        <div
          className="modal-content"
          style={{ maxHeight: "90vh", overflow: "hidden" }}
        >
          <div
            className="modal-header"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "sticky",
              top: 0,
              background: "#fff",
              zIndex: 1000,
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src="assets/logo/tslogo.png"
                alt="Client"
                style={{ width: "25px", height: "100%" }}
              />
              <h5 className="modal-title text-center">Add Company</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            >
              <span aria-hidden="true"></span>
            </button>
          </div>
          <div
            className="modal-body"
            style={{ overflowY: "auto", maxHeight: "calc(100% - 60px)" }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row ">
                <div className="form-group">
                  <input
                    id="company-name"
                    type="text"
                    className={`form-control px-3 ${
                      errors.companyName ? "is-invalid" : ""
                    }`}
                    placeholder="Company Name"
                    {...register("companyName", { required: true })}
                  />
                  {errors.companyName && (
                    <span className="text-danger">Company Name is required</span>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <input
                    id="company-email"
                    type="email"
                    className={`form-control px-3 ${
                      errors.companyEmail ? "is-invalid" : ""
                    }`}
                    placeholder="Company Email"
                    {...register("companyEmail", {
                      required: true,
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
                    })}
                  />
                  {errors.companyEmail && (
                    <span className="text-danger">Please enter a valid email</span>
                  )}
                </div>
                <div className="form-group">
                  <input
                    id="company-contact"
                    type="text"
                    className={`form-control px-3 ${
                      errors.companyContact ? "is-invalid" : ""
                    }`}
                    placeholder="Company Contact"
                    {...register("companyContact", { required: true })}
                  />
                  {errors.companyContact && (
                    <span className="text-danger">Company Contact is required</span>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <input
                    id="company-short-name"
                    type="text"
                    className={`form-control px-3 ${
                      errors.companyShortName ? "is-invalid" : ""
                    }`}
                    placeholder="Company Short Name"
                    {...register("companyShortName", { required: true })}
                  />
                  {errors.companyShortName && (
                    <span className="text-danger">Company Short Name is required</span>
                  )}
                </div>
                <div className="form-group">
                  <input
                    id="company-pincode"
                    type="text"
                    className={`form-control px-3 ${
                      errors.companyPincode ? "is-invalid" : ""
                    }`}
                    placeholder="Company Pincode"
                    {...register("companyPincode", { required: true })}
                  />
                  {errors.companyPincode && (
                    <span className="text-danger">Company Pincode is required</span>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <div className="select-client-container">
                    <Select
                      id="company-country"
                      options={country}
                      placeholder={selectedCountry || "Select Country"}
                      {...register("clientCountry")}
                      isSearchable
                      className="select-client"
                      onChange={handleCountryChange}
                    />
                    {errors.companyCountry && (
                      <span className="text-danger">Country is required</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="select-client-container">
                    <Select
                      id="company-state"
                      options={state}
                      placeholder={selectedState || "Select State"}
                      onChange={handleStateChange}
                      isSearchable
                      className="select-client"
                    />
                    {errors.companyState && (
                      <span className="text-danger">State is required</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="select-client-container">
                    <Select
                      id="company-city"
                      options={city}
                      placeholder={selectedCity || "Select City"}
                      {...register("clientCity")}
                      onChange={handleCityChange}
                      isSearchable
                      className="select-client"
                    />
                    {errors.companyCity && (
                      <span className="text-danger">City is required</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <input
                    id="company-website"
                    type="text"
                    className={`form-control px-3 ${
                      errors.companyWebsite ? "is-invalid" : ""
                    }`}
                    placeholder="Company Website"
                    {...register("companyWebsite", { required: true })}
                  />
                  {errors.companyWebsite && (
                    <span className="text-danger">Company Website is required</span>
                  )}
                </div>
                <div className="form-group">
                  <input
                    id="company-gst"
                    type="text"
                    className={`form-control px-3 ${
                      errors.companyGst ? "is-invalid" : ""
                    }`}
                    placeholder="Company GST"
                    {...register("companyGst", { required: true })}
                  />
                  {errors.companyGst && (
                    <span className="text-danger">Company GST is required</span>
                  )}
                </div>
                <div className="file-input-wrapper">
                  <input
                    id="company-logo"
                    type="file"
                    className="form-control"
                    {...register("companyLogo")}
                    onChange={(e) => handleFileChange(e)}
                    style={{ display: "none" }} // Hide the default input
                  />
                  <label htmlFor="company-logo" className="file-label">
                    Company Logo
                  </label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <textarea
                    id="company-address"
                    className={`form-control px-3 ${
                      errors.companyAddress ? "is-invalid" : ""
                    }`}
                    placeholder="Company Address"
                    {...register("companyAddress", { required: true })}
                  />
                  {errors.companyAddress && (
                    <span className="text-danger">Company Address is required</span>
                  )}
                </div>
                <div className="form-group">
                  <textarea
                    id="terms-condition"
                    className={`form-control px-3 ${
                      errors.termsCondition ? "is-invalid" : ""
                    }`}
                    placeholder="Terms & Condition"
                    {...register("termsCondition", { required: true })}
                  />
                  {errors.termsCondition && (
                    <span className="text-danger">Terms & Condition is required</span>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <input
                    id="facebook"
                    type="text"
                    className="form-control"
                    placeholder="Facebook Link"
                    {...register("facebook")}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="twitter"
                    type="text"
                    className="form-control"
                    placeholder="Twitter Link"
                    {...register("twitter")}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <input
                    id="instagram"
                    type="text"
                    className="form-control"
                    placeholder="Instagram Link"
                    {...register("instagram")}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="linkedIn"
                    type="text"
                    className="form-control"
                    placeholder="LinkedIn Link"
                    {...register("linkedIn")}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-primary">
                  Add Company
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyForm;