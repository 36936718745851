import React, { useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import techsimbalogo from "./techsimbalogo.png";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const PdfComponent = () => {
  const[invoiceData, setInvoiceData]=useState('')
  const location = useLocation();
  const { PDFData } =useSelector((state=>state.PDFSlice));
    
  // const { invoiceData } = location.state || {};
  useEffect(()=>{
    if (PDFData) {
      console.log(PDFData," PDFData")
      setInvoiceData(PDFData? PDFData: [])
    }
  },[PDFData])

  console.log(invoiceData, " invoiceData");
  if (!invoiceData) {
    return <div className="mt-3 text-center">No invoice data found!</div>;
  }

  return (
    <div>
      
      <PDFViewer style={{ width: "100%", height: "90vh" }}>
        <Invoice invoiceData={invoiceData} />
      </PDFViewer>
      <PDFDownloadLink
        document={<Invoice invoiceData={invoiceData} />}
        fileName={`${invoiceData.invoice_number}.pdf`}
        style={{
          backgroundColor: "green",
          padding: 5,
          borderRadius: 10,
          color: "white",
        }}
      >
        {({ loading }) => (loading ? "Preparing document..." : "Download PDF")}
      </PDFDownloadLink>
    </div>
  );
};

const Invoice = ({ invoiceData }) => {

  const hasHours = invoiceData?.Products?.some(
    (item) => item.Hours !== undefined && item.Hours !== ""
  );
  const hasQuantity = invoiceData?.Products?.some(
    (item) => item.Quantity !== undefined && item.Quantity !== ""
  );

  console.log("hasHours:", hasHours);
  console.log("hasQuantity:", hasQuantity);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.watermarkContainer}>
          <Image source={techsimbalogo} style={styles.watermarkLogo} />
        </View>

        <View style={styles.container}>
          <View style={styles.header}>
            <View style={styles.imageContainer}>
              <Image source={techsimbalogo} style={styles.logo} />
            </View>
            <View style={styles.infoContainer}>
              <Text style={styles.companyStyle}>
                {invoiceData.company?.company_name}
              </Text>
              <Text style={styles.companyStyle}>
                {invoiceData.company?.company_address},{invoiceData.company?.company_pincode}
              </Text>
              <Text style={styles.companyStyle}>
                Tel: {invoiceData.company?.company_contact}
              </Text>
              <Text style={styles.companyStyle}>
                E-mail: {invoiceData.company?.company_email}
              </Text>
              <Text style={styles.companyStyle}>
                Web: {invoiceData.company?.company_website}
              </Text>
              <Text style={styles.companyStyle}>
                GSTIN: {invoiceData.company?.company_gst}
              </Text>
            </View>
          </View>
          <Text style={styles.invoiceTitle}>INVOICE</Text>
          <View style={styles.card}>
            <Text style={styles.label}>DATE: {invoiceData.invoice_date}</Text>
            <Text style={styles.label}>
              INVOICE NO.: {invoiceData.invoice_number}
            </Text>
            <Text style={styles.label}>
              COMPANY NAME: {invoiceData.client?.business_name}
            </Text>
            <Text style={styles.label}>ATTN: {invoiceData.client?.first_name}</Text>
          </View>
          <View style={styles.tableContainer}>
            <View style={styles.tableHeader}>
              <Text style={styles.headerPartiCell}>PARTICULARS</Text>
              <Text style={styles.headerCell}>Category</Text>
              {invoiceData?.template == "hours" ? ( <Text style={styles.headerCell}>Hour</Text>):
              <Text style={styles.headerCell}>Qty</Text>
              }
              <Text style={styles.headerCell}>AMOUNT ($)</Text>
            </View>

            {invoiceData?.invoice_items?.map((item, index) => (
              <View style={styles.tableRow} key={index}>
                <Text style={styles.headerPartiValue}>
                <Text style={{fontWeight:'bold'}}>{item.item_name}</Text> {"\n"}
                  <Text style={{fontSize:8}}>{item.description}</Text></Text>
                <Text style={styles.cell}>{item.category}</Text>
                {invoiceData?.template == "hours" ?(
                  <Text style={styles.cell}>{item?.hours || "-"}</Text>
                ):
                
                  <Text style={styles.cell}>{item?.quantity || "-"}</Text>
                }
                <Text style={styles.cell}>{parseInt(item.rate)}</Text>
              </View>
            ))}
          
            <View style={styles.tableRow}>
               <View style={styles.totallabelView}>
                 <Text style={styles.totalLabel}>Discount </Text>
               </View>
               <View
                style={{
                  flex: 1,
                  paddingHorizontal: 10,
                }}
              >
                <Text style={styles.totalValue}> 
                  {parseInt(invoiceData.discount_amount)} %
                </Text>
              </View>
            </View>
            {invoiceData?.other_amounts?.map((item, index) => (
  item.amount ? (
    <View style={styles.tableRow} key={index}>
      <View style={styles.totallabelView}>
        <Text style={styles.totalLabel}>{item.name}</Text>
      </View>
      <View
        style={{
          flex: 1,
          padding: 10,
        }}
      >
        <Text style={styles.totalValue}>{parseInt(item.amount)}</Text>
      </View>
    </View>
  ) : null
))}
            <View style={styles.totalRow}>
              <View style={styles.totallabelView}>
                <Text style={styles.totalLabel}>Total : </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  padding: 10,
                }}
              >
                <Text style={styles.totalValue}>{invoiceData.total}</Text>
              </View>
            </View>
          </View>
          
                  {invoiceData.indirect_bank? (
          <View style={styles.card}>
            <Text style={styles.title}>BANK DETAILS:</Text>
            <Text style={styles.label}>
              Beneficiary name: {invoiceData.indirect_bank?.account_number}
            </Text>
            <Text style={styles.label}>Bank name: {invoiceData.indirect_bank?.bank_name}</Text>
            <Text style={styles.label}>
              Account number: {invoiceData.indirect_bank?.account_number}
            </Text>
            <Text style={styles.label}>
              Bank address: {invoiceData.indirect_bank?.bank_address}
            </Text>
            <Text style={styles.label}>Branch code: {invoiceData.indirect_bank?.branch_code}</Text>
            <Text style={styles.label}>Bank code: {invoiceData.indirect_bank?.bank_code}</Text>
          </View>
):
          <View style={styles.card}>
            <Text style={styles.title}>BANK DETAILS:</Text>
            <Text style={styles.label}>
              Beneficiary name: {invoiceData.direct_bank?.account_number}
            </Text>
            <Text style={styles.label}>Bank name: {invoiceData.direct_bank?.bank_name}</Text>
            <Text style={styles.label}>
              Account number: {invoiceData.direct_bank?.account_number}
            </Text>
            <Text style={styles.label}>
              Bank address: {invoiceData.direct_bank?.bank_address}
            </Text>
            <Text style={styles.label}>Branch code: {invoiceData.direct_bank?.branch_code}</Text>
            <Text style={styles.label}>Bank code: {invoiceData.direct_bank?.bank_code}</Text>
          </View>
}
          <View>
            <Text style={styles.title}>INVOICE TERMS:</Text>
            <Text style={styles.label}>{invoiceData?.terms_condition}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 20,
    position: "relative",
  },
  container: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    overflow: "hidden",
    zIndex: 1,
  },
  watermarkContainer: {
    position: "absolute",
    top: "30%",
    left: "20%",
    zIndex: 0,
    opacity: 0.1,
  },
  watermarkLogo: {
    width: 400,
    height: 300,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginBottom: 10,
    zIndex: 2,
  },
  imageContainer: {
    flex: 2,
    justifyContent: "center",
  },
  logo: {
    width: 200,
    height: 100,
  },
  infoContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  companyStyle: {
    fontSize: 12,
    marginBottom: 4,
    textAlign: "left",
  },
  invoiceTitle: {
    textAlign: "center",
    marginVertical: 10,
    textDecoration: "underline",
    fontSize: 16,
    fontWeight: "bold",
  },
  title: {
    textAlign: "left",
    marginVertical: 10,
    fontSize: 14,
    fontWeight: "bold",
  },
  card: {
    marginVertical: 10,
  },
  tableContainer: {
    borderWidth: 1,
    borderColor: "#000",
    marginVertical: 10,
  },
  tableHeader: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#000",
    backgroundColor: "#f4f4f4",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#000",
  },
  headerCell: {
    flex: 1,
    padding: 5,
    fontSize: 12,
    borderRightWidth: 1,
    borderColor: "#000",
    fontWeight: "bold",
    textAlign: "center",
  },
  headerPartiCell: {
    flex: 3,
    padding: 5,
    fontSize: 12,
    borderRightWidth: 1,
    borderColor: "#000",
    fontWeight: "bold",
    textAlign: "center",
  },
  headerPartiValue: {
    flex: 3.1,
    padding: 8,
    borderRightWidth: 1,
    borderColor: "#000",
    fontSize: 10,
    textAlign: "center",
  },
  cell: {
    flex: 1,
    padding: 8,
    borderRightWidth: 1,
    borderColor: "#000",
    fontSize: 10,
    textAlign: "center",
  },
  totallabelView: {
    flex: 5.67,
    paddingHorizontal: 10,
    borderRightWidth: 1,
    borderRightColor: "#000",
    alignItems: "flex-end",
  },
  cellValue: {
    flex: 1,
    padding: 8,
    borderRightWidth: 1,
    borderColor: "#000",
    fontSize: 10,
    textAlign: "center",
  },
  label: {
    fontWeight: "bold",
    fontSize: 12,
    padding: 2,
  },
  totalRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems:'center',
  },
  totalLabel: {
    fontWeight: "bold",
    padding: 10,
    textAlign:'center',
    fontSize: 12,
    padding: 2,
  },
  totalValue: {
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    padding: 2,
  },
});

export default PdfComponent;
