import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BASE_URL, clientGet } from '@Networking/APIs/NWConfig';
import Cookies from 'js-cookie';


//Country Api
export const getClient = createAsyncThunk(
    'get/getClient',
    async () => {  
      // console.log(clientGet, "url111");   
  
      let url = BASE_URL + clientGet; 
      // console.log(url, "url");   
  
      const token = Cookies.get('authToken');
      // console.log(token,"tokenn");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        // console.log("response ", response);
        return Promise.resolve(response);
      } catch (error) {
        // console.log(error,"api error")
        return Promise.reject(error);
      }
    },
  );
  