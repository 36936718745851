import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BASE_URL, getitems} from '@Networking/APIs/NWConfig';
import Cookies from 'js-cookie';


export const getItem = createAsyncThunk(
    'get/getItem',
    async () => {  // categories should be passed as an argument
      // console.log(getitems, "url111");   
  
      // Using template literals to build the URL
      let url = BASE_URL + getitems;  // Corrected line
      // console.log(url, "url");   
  
      const token = Cookies.get('authToken');
    //   console.log(token,"tokenn");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        // console.log("response ", response);
        return Promise.resolve(response);
      } catch (error) {
        // console.log(error,"api error")
        return Promise.reject(error);
      }
    },
  );
  