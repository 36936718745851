import React from "react";
import "../../Invoice/invoicesForm.css";
import { postItemStore } from "@Networking/APIs/Item/itemApi";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getItem } from "@Networking/APIs/Item/getitem";

const ItemForm = ({ onClose }) => {
  const dispatch = useDispatch();
  
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    const newItem = {
      name: data.itemName,
      price: parseFloat(data.itemPrice),
      description: data.itemDescription,
    };
  
    try {
      await dispatch(postItemStore(newItem));
      await dispatch(getItem());
      onClose();
    } catch (error) {
      console.error("Error in submitting item:", error);
    }
  };
  
  return (
    <div className="modal fade show"
      style={{ display: "block", backdropFilter: "blur(5px)" }}
      aria-modal="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header"
          style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src="assets/logo/tslogo.png"
                alt="Client"
                style={{ width: "25px", height: "100%",  }}
              />
            <h5 className="modal-title">Add Item</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            >
              <span aria-hidden="true"></span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control px-3 ${
                    errors.itemName ? "is-invalid" : ""
                  }`}
                  id="itemName"
                  placeholder="Item name"
                  {...register("itemName", { required: "Item name is required" })}
                />
                {errors.itemName && <span className="text-danger">{errors.itemName.message}</span>}
              </div>
              <div className="form-group">
                <input
                  type="number"
                  className={`form-control px-3 ${
                    errors.itemPrice ? "is-invalid" : ""
                  }`}
                  id="itemPrice"
                  placeholder="Item price"
                  {...register("itemPrice", { 
                    required: "Item price is required",
                    valueAsNumber: true,
                    validate: value => value > 0 || "Price must be greater than 0"
                  })}
                />
                {errors.itemPrice && <span className="text-danger">{errors.itemPrice.message}</span>}
              </div>
              <div className="form-group">
                <textarea
                  className={`form-control px-3 ${
                    errors.itemDescription ? "is-invalid" : ""
                  }`}
                  id="itemDescription"
                  placeholder="Item description"
                  {...register("itemDescription",{ required: "Item Description is required" })}
                />
                {errors.itemDescription && <span className="text-danger">{errors.itemDescription.message}</span>}
              </div>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemForm;
