import { configureStore } from '@reduxjs/toolkit';
import clientDetailReducer from '@Networking/Slices/Client/clientSlice'
import backgroundReducer from "@Networking/Slices/Background/backgroundSlice"
import loginReducer from "@Networking/Slices/Login/loginSlice"
import ItemReducer from "@Networking/Slices/Item/itemSlice"
import categoryReducer from "@Networking/Slices/Category/categorySlice"
import BankStoreReducer from "@Networking/Slices/Bank/bankStoreSlice"
import CompanyStoreReducer from "@Networking/Slices/Company/companyStoreSlice"
import GetInReducer from "@Networking/Slices/Invoice/InvoiceNumberSlice"
import PDFReducer from "@Networking/Slices/PDF/pdfSlice"

export const store = configureStore({
    reducer: {
        BackgroundSlice: backgroundReducer,
        LoginSlice: loginReducer,
        clientSlice:clientDetailReducer,
        ItemSlice:ItemReducer,
        CategorySlice: categoryReducer,
        BankPostSlice: BankStoreReducer,
        CompanyStoreSlice: CompanyStoreReducer,
        GetInSlice: GetInReducer,
        PDFSlice:PDFReducer
  },
});