import { createSlice } from '@reduxjs/toolkit';
import { postBankStore } from '@Networking/APIs/Bank/bankStoreApi';
import { getBank, getindirctBank } from '@Networking/APIs/Bank/bankGetApi';
import { toast } from 'react-toastify';

const BankStoreSlice = createSlice({
  name: 'BankPostSlice',
  initialState: {
    loading: false,
    BankStore: '',
    AllBankData:[],
    IndirectBank:[],
    success: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(postBankStore.pending, (state) => {
      // console.log("Pending....");
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postBankStore.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.BankStore = action.payload;
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
        // console.log("this is error ");
      }
      // console.log(state.BankStore ," state.BankStore")
    });
    builder.addCase(postBankStore.rejected, (state, action) => {
       state.loading = false;
      state.error = action.error.message || 'Login failed.';
    });


    // Get Bank 
    builder.addCase(getBank.pending, (state) => {
       state.loading = true;
      state.error = null;
    });
    builder.addCase(getBank.fulfilled, (state, action) => {
       state.loading = false;
      state.success = true;
      state.AllBankData = action.payload;
      // console.log(state.AllBankData ," state.Bank get")
    });
    builder.addCase(getBank.rejected, (state, action) => {
       state.loading = false;
      state.error = action.error.message || 'Login failed.';
    });

     // Get indirectBank 
     builder.addCase(getindirctBank.pending, (state) => {
      state.loading = true;
     state.error = null;
   });
   builder.addCase(getindirctBank.fulfilled, (state, action) => {
      state.loading = false;
     state.success = true;
     state.IndirectBank = action.payload;
    //  console.log(state.IndirectBank ," state.IndirectBank get")
   });
   builder.addCase(getindirctBank.rejected, (state, action) => {
      state.loading = false;
     state.error = action.error.message || 'Login failed.';
   });
  },
});

export default BankStoreSlice.reducer;