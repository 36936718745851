import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL,items } from "@Networking/APIs/NWConfig";
import Cookies from 'js-cookie';


export const postItemStore = createAsyncThunk(
    "post/postItemStore",
    async (post) => {
        let url = BASE_URL + items;
        // console.log("postItemStore Called:", post, url);

        const token = Cookies.get('authToken');

        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })).data;

            // console.log(response, " response");
            return Promise.resolve(response);
        } catch (error) {
            console.error("error:", error);
            // Handle specific error types
            if (error.response && error.response.status === 400) {
                return Promise.reject("Invalid data format");
            } else if (error.response && error.response.status === 401) {
                return Promise.reject("Unauthorized access");
            } else {
                return Promise.reject("An error occurred. Please try again later.");
            }
        }
    }
);