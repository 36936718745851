import React, { useMemo, useState, useEffect, useRef } from "react";
import { useTable, useGlobalFilter } from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { getallinvoices } from "@Networking/APIs/Invoice/invoiceApi";
import { useDispatch, useSelector } from "react-redux";
import Loader from "@Layout/Loader/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faEye,
  faEdit,
  faDownload,
  faMoneyBillWave,
} from "@fortawesome/free-solid-svg-icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfComponent from "component/PDF/pdfView";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getPDFData } from "@Networking/APIs/PDF/pdf";
import { PDFData } from "@Networking/APIs/NWConfig";
import { toast } from "react-toastify";

// Global filter component for searching
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <span>
      Search:{" "}
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
        placeholder="Search all columns"
        className="form-control"
        style={{ width: "200px", display: "inline-block", marginLeft: "10px" }}
      />
    </span>
  );
};

// Define CSS classes for status background colors
const getStatusClass = (status) => {
  switch (status) {
    case "Unpaid":
      return "badge badge-danger text-white";
    case "Paid":
      return "badge badge-success text-white";
    case "Partially Paid":
      return "badge badge-info text-white";
    default:
      return "";
  }
};

const InvoiceList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { AllInvoices } = useSelector((state) => state.GetInSlice);
  const [invoiceData, setInvoiceData] = useState([]);
  const cardRef = useRef(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedstatus, setSelectedstatus] = useState("");
  const [selectedproduct, setSelectedprocduct] = useState("");
  const [selectedclient, setSelectedclient] = useState("");
  const tableRef = useRef(null);

  const tableHeaderRef = useRef(null);
  const tableBodyRef = useRef(null);
  // Fetch invoices once

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getallinvoices());
      } catch (error) {
        console.error("Error in fetching Invoice data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (AllInvoices) {
      setInvoiceData(AllInvoices.data);
      // console.log(AllInvoices.data, "AllInvoices.data");
    }
  }, [AllInvoices]);

  const data = useMemo(() => {
    return invoiceData
      ? invoiceData?.map((invoice) => ({
          ID: invoice.id || "NA",
          Invoice_No: invoice.invoice_number || "N/A",
          Invoice_Date: invoice.invoice_date || "N/A",
          invoice_url: invoice.invoice_url || "N/A",
          Due_Date: invoice.due_date || "N/A",
          status: invoice.status || "N/A",
          total: `${invoice.total || "N/A"} ${invoice.currency || "N/A"}`,
          Discount: invoice.discount || "N/A",
          Currency: invoice.currency || "N/A",
          Templete: invoice.template || "N/A",

          // Client Details
          client_name: invoice.client_name || "N/A",
          Client_Address: invoice.client_address || "N/A",
          business_name: invoice.business_name || "N/A",
          Client_City: invoice.client_city || "N/A",
          Client_State: invoice.client_state || "N/A",
          Client_Country: invoice.client_country || "N/A",
          Client_Pincode: invoice.client_pincode || "N/A",
          Client_Email: invoice.client_email || "N/A",
          Client_Phone: invoice.client_phone || "N/A",

          // Company Details
          Company_Name: invoice.company_name || "N/A",
          Company_Short_Name: invoice.company_short_name || "N/A",
          Company_Address: invoice.company_address || "N/A",
          Company_City: invoice.company_city || "N/A",
          Company_State: invoice.company_state || "N/A",
          Company_Country: invoice.company_country || "N/A",
          Company_Pincode: invoice.company_pincode || "N/A",
          Company_Email: invoice.company_email || "N/A",
          Company_Contact: invoice.company_contact || "N/A",
          Company_GST: invoice.company_gst || "N/A",
          Company_Website: invoice.company_website || "N/A",
          Company_Logo: invoice.company_logo || "N/A",

          // Bank Details
          Bank_Name: invoice.bank_name || "N/A",
          Branch: invoice.branch || "N/A",
          Account_Name: invoice.account_name || "N/A",
          Account_Number: invoice.account_number || "N/A",
          Account_Type: invoice.account_type || "N/A",
          Bank_Address: invoice.bank_address || "N/A",
          Bank_City: invoice.bank_city || "N/A",
          IFSC_Code: invoice.ifsc_code || "N/A",

          // Other Details
          Reference_Number: invoice.reference_number || "N/A",
          Terms_Condition: invoice.terms_condition || "N/A",

          // Other Amounts
          other_amounts:
            invoice.other_amounts?.map((otheramounts) => ({
              name: otheramounts.name || "N/A",
              amount: otheramounts.amount || "N/A",
            })) || [],
          Discount: invoice.discount,

          // Product Details
          Products:
            invoice?.products?.map((product) => ({
              itemLabel: product?.itemLabel || "N/A",
              Quantity: product?.quantity || "N/A",
              Hours: product?.hours || "N/A",
              Price: product?.price || "N/A",
              Discount_value: product?.discountValue || "N/A",
              category: product?.categoryLabel || "NA",
              Discribtion: product?.description,
            })) || [],
        }))
      : null;
  }, [invoiceData]);

  const handleEdit = (invoicedata) => {
    // console.log("Edit invoice:", invoice);

    navigate("/InvoiceEdit", { state: { invoicedata } });
  };

  const handleView = (invoice, index) => {
    if (invoice.invoice_url === undefined) {
      console.error(`Invoice at index ${index} has undefined invoice_url`);
      return;
    }

    const currentUrl = invoice.invoice_url;
    // console.log(currentUrl, " currentUrl");

    const anchor = document.createElement("a");
    anchor.href = `https://developbo.techsimba.in/management/public/downloadInvoice/${currentUrl}`;
    anchor.target = "_blank";
    anchor.rel = "noopener noreferrer";

    anchor.click();
  };

  const handlePDF = (invoiceData) => {
    dispatch(getPDFData(invoiceData.ID));
    navigate("/PDF");
  };

  const handleDownload = (invoicedata) => {
    console.log(invoicedata, " invoicedata");
    navigate("/InvoicePartially", { state: { invoicedata } });
    // const fileName = `${invoiceData.Invoice_No}.pdf`;
    // const link = document.createElement("a");
    // document.body.appendChild(link);
    // link.style.display = "none";

    // const DownloadPDFLink = (
    //   <PDFDownloadLink
    //     document={<PdfComponent invoiceData={invoiceData} />}
    //     fileName={fileName}
    //   >
    //     {({ blob, url, loading, error }) => {
    //       if (!loading && url) {
    //         link.href = url;
    //         link.download = fileName;
    //         link.click();
    //         document.body.removeChild(link);
    //       }
    //     }}
    //   </PDFDownloadLink>
    // );

    // return DownloadPDFLink;
  };

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "ID" },
      { Header: "Invoice NO.", accessor: "Invoice_No" },
      { Header: "Client Name", accessor: "client_name" },
      {
        Header: "Items",
        accessor: "Products",
        Cell: ({ cell: { value } }) => (
          <ul>
            {value.map((product, index) => (
              <span className="text-wrap" key={index}>
                {product.itemLabel}
              </span>
            ))}
          </ul>
        ),
      },
      { Header: "Total", accessor: "total" },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span className={`p-2 ${getStatusClass(value)}`}>{value}</span>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row, index }) => (
          <div>
            {/* <button
              className="btn btn-info btn-sm mx-1"
              onClick={() => handleView(row.original, index)}
            >
              <FontAwesomeIcon icon={faEye} />
            </button> */}

            <button
              className="btn btn-danger btn-sm mx-1"
              onClick={() => handlePDF(row.original, index)}
            >
              <FontAwesomeIcon icon={faFilePdf} />
            </button>

            <button
              className="btn btn-warning btn-sm mx-1"
              onClick={() => handleEdit(row.original)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>

            <button
              className="btn btn-success btn-sm mx-1"
              onClick={() => handleDownload(row.original)}
            >
              <FontAwesomeIcon icon={faMoneyBillWave} />
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter);

  const { globalFilter } = state;

  const handleDateFilter = () => {
    const filteredData = invoiceData.filter((invoice) => {
      const invoiceDate = new Date(invoice.Invoice_Date);
      return invoiceDate >= startDate && invoiceDate <= endDate;
    });

    setInvoiceData(filteredData);
  };

  // Add scroll event listener

  useEffect(() => {
    const tableBody = tableBodyRef.current;
    const tableHeader = tableHeaderRef.current;

    if (!tableBody || !tableHeader) return;

    const handleScroll = () => {
      const scrollTop = tableBody.scrollTop;
      if (scrollTop > 0) {
        tableHeader.style.position = "fixed";
        tableHeader.style.top = 0;
        tableHeader.style.width = "100%";
        tableHeader.style.zIndex = 10;
      } else {
        tableHeader.style.position = "";
        tableHeader.style.top = "";
        tableHeader.style.width = "";
        tableHeader.style.zIndex = "";
      }
    };

    // Attach the scroll listener to the table body
    tableBody.addEventListener("scroll", handleScroll);

    // Cleanup the scroll listener on component unmount
    return () => {
      tableBody.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="container-fuild">
        <div className="row">
          <div className="col-md-12">
            <div className="card shadow-sm invoice-card rounded-2 border-0 ">
              <div className="card text-white rounded-top-2 p-3">
                <div className="row d-flex align-items-center">
                  <div className="col-md-5 col-sm-12">
                    <h3 className="page-title m-0">
                      <span className="page-title-icon mx-2 text-primary">
                        <i className="mdi mdi-file"></i>
                      </span>
                      INVOICE LIST
                    </h3>
                  </div>

                  <div className="col-md-4 col-sm-12 align-items-center">
                    <GlobalFilter
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                  </div>

                  <div className="col-md-3 col-sm-12 text-end">
                    <Link to="/InvoicesForm">
                      <button className="btn btn-gradient-info mx-3 px-4 fw-bold shadow-sm">
                        <Link
                          to="/InvoicesForm"
                          className="text-light text-decoration-none"
                        >
                          Add Invoice
                        </Link>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              {/* <div className="card-body">
                <div className="row d-flex flex-wrap">
                  <div className="col-md-6 mb-3 mt-3">
                    <div className="form-group">
                      <label
                        htmlFor="startDate"
                        className="text-dark font-weight-bold"
                      >
                        Start Date:
                      </label>
                      <DatePicker
                        id="startDate"
                        className="form-control mt-1"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mb-3 mt-3">
                    <div className="form-group">
                      <label
                        htmlFor="endDate"
                        className="text-dark font-weight-bold"
                      >
                        End Date:
                      </label>
                      <DatePicker
                        id="endDate"
                        className="form-control mt-1"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mb-3 mt-3">
                    <div className="form-group">
                      <label
                        htmlFor="filterSelect"
                        className="text-dark font-weight-bold mb-2"
                      >
                        Filter by:
                      </label>
                      <select
                        id="filterSelect"
                        className="form-control mt-1"
                        value={selectedFilter}
                        onChange={(e) => setSelectedFilter(e.target.value)}
                      >
                        <option value="">Select Filter</option>
                        <option value="filter1">Client</option>
                        <option value="filter2">Product</option>
                        <option value="filter3">Status</option>
                      </select>
                    </div>
                  </div>

                  {selectedFilter === "filter3" && (
                    <div className="col-md-6 mb-3 mt-3">
                      <div className="form-group">
                        <label
                          htmlFor="statusSelect"
                          className="text-dark font-weight-bold"
                        >
                          Status:
                        </label>
                        <select
                          id="statusSelect"
                          className="form-control mt-1"
                          value={selectedstatus}
                          onChange={(e) => setSelectedstatus(e.target.value)}
                        >
                          <option value="">Select Status</option>
                          <option value="paid">Paid</option>
                          <option value="unpaid">Unpaid</option>
                          <option value="partiallyPaid">Partially Paid</option>
                        </select>
                      </div>
                    </div>
                  )}

                  {selectedFilter === "filter2" && (
                    <div className="col-md-6 mb-3 mt-3">
                      <div className="form-group">
                        <label
                          htmlFor="productSelect"
                          className="text-dark font-weight-bold"
                        >
                          Product:
                        </label>
                        <select
                          id="productSelect"
                          className="form-control mt-1"
                          value={selectedproduct}
                          onChange={(e) => setSelectedprocduct(e.target.value)}
                        >
                          <option value="">Select Product</option>
                          <option value="one2one">one2one</option>
                          <option value="NMM">NMM</option>
                          <option value="connection">Connection</option>
                        </select>
                      </div>
                    </div>
                  )}

                  {selectedFilter === "filter1" && (
                    <div className="col-md-6 mb-3 mt-3 ">
                      <div className="form-group">
                        <label
                          htmlFor="clientSelect"
                          className="text-dark font-weight-bold"
                        >
                          Client:
                        </label>
                        <select
                          id="clientSelect"
                          className="form-control mt-1"
                          value={selectedclient}
                          onChange={(e) => setSelectedclient(e.target.value)}
                        >
                          <option value="">Select Client</option>
                          <option value="Rohit">Rohit Bansal</option>
                          <option value="Michelle">Michelle Schuhen</option>
                          <option value="Rik">Rik Spruyt</option>
                        </select>
                      </div>
                    </div>
                  )}

                  <div className="col-md-12 mb-3 mt-3">
                    <button
                      className="btn btn-primary px-4 py-2 fw-bold shadow-sm"
                      onClick={handleDateFilter}
                    >
                      Filter Invoices
                    </button>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="card mt-3 invoice-card" ref={tableRef}>
              <div
                className="card-body"
                style={{ height: "550px", overflowY: "auto" }}
              >
                <table {...getTableProps()} className="table table-hover">
                  <thead
                    style={{
                      position: "sticky",
                      top: -25,
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                      rows.slice().map((row, index) => {
                        prepareRow(row);
                        return (
                          <tr key={index} {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={columns.length}>
                          <p>No Invoices</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {isLoadingMore && (
                <div className="text-center mt-3">
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceList;
