import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { postBankStore } from "@Networking/APIs/Bank/bankStoreApi";
import { useDispatch, useSelector } from "react-redux";
import { getBank } from "@Networking/APIs/Bank/bankGetApi";
import { getcountries } from "@Networking/APIs/Background/backgorundApi";

const BankForm = ({ onClose, onAddBank }) => {
  const { country } = useSelector((state) => state.BackgroundSlice);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getcountries());
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const newBank = {
      bank_name: data.bankName,
      bank_branch: data.branch,
      bank_code: data.bankCode,
      branch_code: data.branchCode,
      bank_address: data.address,
      bank_country: data.country,
      account_number: data.accountNumber,
      beneficiary_name: data.beneficiaryName,
      swift_code: data.swiftcode,
      ifsc: data.ifscCode,
      account_type: data.accountType,
    };
    try {
      await dispatch(postBankStore(newBank));
      await dispatch(getBank());
      onClose();
    } catch (error) {
      console.error("Error in submitting bank:", error);
    }
  };

  return (
    <div
      className="modal fade show"
      style={{ display: "block", backdropFilter: "blur(5px)" }}
      aria-modal="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src="assets/logo/tslogo.png"
                alt="Client"
                style={{ width: "25px", height: "100%" }}
              />
              <h5 className="modal-title text-center">Add Bank</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row">
                <div className="form-group col-1">
                  <select
                 style={{
                   
                  color: "#686868"   
              }}
                    {...register("accountType", {
                      required: "Account Type is required",
                    })}
                    id="account-type"
                    placeholder="Select Account Type"
                    className={`form-control px-3 ${
                      errors.accountType ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">Select Account Type</option>
                    <option value="Direct">Direct</option>
                    <option value="Indirect">Indirect</option>
                  </select>
                  {errors.accountType && (
                    <p className="text-danger">{errors.accountType.message}</p>
                  )}
                </div>
                <div className="form-group">
                  <input
                    {...register("beneficiaryName", {
                      required: "Beneficiary Name is required",
                    })}
                    id="beneficiary-name"
                    type="text"
                    className={`form-control px-3 ${
                      errors.beneficiaryName ? "is-invalid" : ""
                    }`}
                    placeholder="Beneficiary Name"
                  />
                  {errors.beneficiaryName && (
                    <p className="text-danger">
                      {errors.beneficiaryName.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Address and Country */}
              <div className="form-row">
                <div className="form-group col-1">
                  <select
                   style={{
                   
                    color: "#686868"   
                }}
                    {...register("country", {
                      required: "Country is required",
                    })}
                    id="country"
                    className={`form-control px-3 ${
                      errors.country ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">Select Country</option>
                    {country.map((c, index) => (
                      <option key={index} value={c.value}>
                        {c.label}
                      </option>
                    ))}
                  </select>
                  {errors.country && (
                    <p className="text-danger">{errors.country.message}</p>
                  )}
                </div>
                <div className="form-group">
                  <input
                    {...register("bankName", {
                      required: "Bank Name is required",
                    })}
                    id="bank-name"
                    type="text"
                    className={`form-control px-3 ${
                      errors.bankName ? "is-invalid" : ""
                    }`}
                    placeholder="Bank Name"
                  />
                  {errors.bankName && (
                    <p className="text-danger">{errors.bankName.message}</p>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <input
                    {...register("branch", { required: "Branch is required" })}
                    id="branch"
                    type="text"
                    placeholder="Branch"
                    className={`form-control px-3 ${
                      errors.branch ? "is-invalid" : ""
                    }`}
                  />
                  {errors.branch && (
                    <p className="text-danger">{errors.branch.message}</p>
                  )}
                </div>
                <div className="form-group">
                  <input
                    {...register("accountNumber", {
                      required: "Account Number is required",
                    })}
                    id="account-number"
                    type="text"
                    className={`form-control px-3 ${
                      errors.accountNumber ? "is-invalid" : ""
                    }`}
                    placeholder="Account Number"
                  />
                  {errors.accountNumber && (
                    <p className="text-danger">
                      {errors.accountNumber.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Bank Code and Branch Code */}
              <div className="form-row">
                <div className="form-group">
                  <input
                    {...register("bankCode", {
                      required: "Bank Code is required",
                    })}
                    id="bank-code"
                    type="text"
                    placeholder="Enter Bank Code"
                    className={`form-control px-3 ${
                      errors.bankCode ? "is-invalid" : ""
                    }`} // Conditional styling for error
                  />
                  {errors.bankCode && (
                    <p className="text-danger error-message">
                      {errors.bankCode.message}
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <input
                    {...register("branchCode", {
                      required: "Branch Code is required",
                    })}
                    id="branch-code"
                    type="text"
                    placeholder="Branch Code"
                    className={`form-control px-3 ${
                      errors.branchCode ? "is-invalid" : ""
                    }`}
                  />
                  {errors.branchCode && (
                    <p className="text-danger">{errors.branchCode.message}</p>
                  )}
                </div>
              </div>

              {/* Account Number and Swift Code */}
              <div className="form-row">
                <div className="form-group">
                  <input
                    {...register("swiftcode", {
                      required: "Swift Code is required",
                    })}
                    id="swift-code"
                    type="text"
                    className={`form-control px-3 ${
                      errors.swiftcode ? "is-invalid" : ""
                    }`}
                    placeholder="Swift Code"
                  />
                  {errors.swiftcode && (
                    <p className="text-danger">{errors.swiftcode.message}</p>
                  )}
                </div>
                <div className="form-group">
                  <input
                    {...register("ifscCode", {
                      required: "IFSC Code is required",
                    })}
                    id="ifsc-code"
                    type="text"
                    className={`form-control px-3 ${
                      errors.ifscCode ? "is-invalid" : ""
                    }`}
                    placeholder="IFSC Code"
                  />
                  {errors.ifscCode && (
                    <p className="text-danger">{errors.ifscCode.message}</p>
                  )}
                </div>
              </div>

              {/* IFSC Code and Account Type */}
              <div className="form-row">
                <div className="form-group">
                  <textarea
                    {...register("address", {
                      required: "Address is required",
                    })}
                    id="address"
                    type="text"
                    placeholder="Address"
                    className={`form-control px-3 ${
                      errors.address ? "is-invalid" : ""
                    }`}
                  />
                  {errors.address && (
                    <p className="text-danger">{errors.address.message}</p>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-primary">
                  Add Bank
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankForm;
