import React, { useEffect } from 'react';

import Cookies from 'js-cookie';


 
const Dashboard = () => {
  
  return (
    <>
    <div className="page-header">
      <h3 className="page-title">
        <span className="page-title-icon bg-gradient-primary text-white me-2">
          <i className="mdi mdi-home"></i>
        </span> Dashboard
      </h3>
      <nav aria-label="breadcrumb">
        <ul className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            <span></span>Overview <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
          </li>
        </ul>
      </nav>
    </div>
    <div className="row">
      <div className="col-md-4 stretch-card grid-margin">
        <div className="card bg-gradient-danger card-img-holder text-white">
          <div className="card-body">
            <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
            <h4 className="font-weight-normal mb-3">TOTAL LEADS<i className="mdi mdi-chart-line mdi-24px float-end"></i>
            </h4>
            <h2 className="mb-5">$ 15,0000</h2>
            <h6 className="card-text">Increased by 60%</h6>
          </div>
        </div>
      </div>
      <div className="col-md-4 stretch-card grid-margin">
        <div className="card bg-gradient-info card-img-holder text-white">
          <div className="card-body">
            <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
            <h4 className="font-weight-normal mb-3">APPROVED PROJECT<i className="mdi mdi-bookmark-outline mdi-24px float-end"></i>
            </h4>
            <h2 className="mb-5">45,6334</h2>
            <h6 className="card-text">Decreased by 10%</h6>
          </div>
        </div>
      </div>
      <div className="col-md-4 stretch-card grid-margin">
        <div className="card bg-gradient-success card-img-holder text-white">
          <div className="card-body">
            <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
            <h4 className="font-weight-normal mb-3">TOTAL CLIENT<i className="mdi mdi-diamond mdi-24px float-end"></i>
            </h4>
            <h2 className="mb-5">95,5741</h2>
            <h6 className="card-text">Increased by 5%</h6>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-4 stretch-card grid-margin">
        <div className="card bg-gradient-purple card-img-holder text-white">
          <div className="card-body">
            <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
            <h4 className="font-weight-normal mb-3">TOTAL PROSPECT<i className="mdi mdi-chart-line mdi-24px float-end"></i>
            </h4>
            <h2 className="mb-5">$ 15,0000</h2>
            <h6 className="card-text">Increased by 60%</h6>
          </div>
        </div>
      </div>
      <div className="col-md-4 stretch-card grid-margin">
        <div className="card bg-gradient-pink card-img-holder text-white">
          <div className="card-body">
            <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
            <h4 className="font-weight-normal mb-3">TOTAL INVOICE<i className="mdi mdi-bookmark-outline mdi-24px float-end"></i>
            </h4>
            <h2 className="mb-5">45,6334</h2>
            <h6 className="card-text">Decreased by 10%</h6>
          </div>
        </div>
      </div>
      <div className="col-md-4 stretch-card grid-margin">
        <div className="card bg-gradient-orange card-img-holder text-white">
          <div className="card-body">
            <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
            <h4 className="font-weight-normal mb-3">TOTAL PAID<i className="mdi mdi-diamond mdi-24px float-end"></i>
            </h4>
            <h2 className="mb-5">95,5741</h2>
            <h6 className="card-text">Increased by 5%</h6>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-4 stretch-card grid-margin">
        <div className="card bg-gradient-teal card-img-holder text-white">
          <div className="card-body">
            <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
            <h4 className="font-weight-normal mb-3">TOTAL EXPENSES<i className="mdi mdi-chart-line mdi-24px float-end"></i>
            </h4>
            <h2 className="mb-5">$ 15,0000</h2>
            <h6 className="card-text">Increased by 60%</h6>
          </div>
        </div>
      </div>
      <div className="col-md-4 stretch-card grid-margin">
        <div className="card bg-gradient-indigo card-img-holder text-white">
          <div className="card-body">
            <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
            <h4 className="font-weight-normal mb-3">TOTAL INVOICE ($) <i className="mdi mdi-bookmark-outline mdi-24px float-end"></i>
            </h4>
            <h2 className="mb-5">45,6334</h2>
            <h6 className="card-text">Decreased by 10%</h6>
          </div>
        </div>
      </div>
      <div className="col-md-4 stretch-card grid-margin">
        <div className="card bg-gradient-amber card-img-holder text-white">
          <div className="card-body">
            <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
            <h4 className="font-weight-normal mb-3">TOTAL PAID ($) <i className="mdi mdi-diamond mdi-24px float-end"></i>
            </h4>
            <h2 className="mb-5">95,5741</h2>
            <h6 className="card-text">Increased by 5%</h6>
          </div>
        </div>
      </div>
    </div>
    
    </>
  );
};

export default Dashboard;
