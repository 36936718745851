import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postlogin } from "@Networking/APIs/Login/loginApi";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import "./logincss.css";
import { toast } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [isLoading, setIsLoading] = useState(false);
  const [hasNavigated, setHasNavigated] = useState(false); 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      alert("Enter username & password");
      return;
    }
    setIsLoading(true);

    try {
      const response = await dispatch(postlogin({ email, password }));

      if (response.payload) {
        const { two_factor, access_token, user } = response.payload;

        // Set cookies
        Cookies.set("authToken", access_token);
        Cookies.set("name", user.name);
        Cookies.set("role", response.payload.role);
        Cookies.set("id", user.id);

        if (two_factor) {
          Cookies.set("otp_id", response.payload.otp_id);
          navigate("/dashboard");
        } else {
          // navigate("/dashboard");
        }
        setHasNavigated(true); 
      }
    } catch (error) {
      console.error("Login Error:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleGoogleLogin = () => {
    const apiUrl = 'https://developbo.techsimba.in/management/api/auth/google';
    window.location.href = apiUrl;
  };

  // Effect to handle user data from URL after Google login
  useEffect(() => {
    if (hasNavigated) return; 

    const queryParams = new URLSearchParams(window.location.search);
    const userData = queryParams.get('user');
    const errorMessage = queryParams.get('error');

    if (userData) {
      try {
        const parsedUser = JSON.parse(decodeURIComponent(userData));
        // Set cookies
        Cookies.set("authToken", parsedUser.access_token);
        Cookies.set("name", parsedUser.user.name);
        Cookies.set("role", parsedUser.role);
        Cookies.set("id", parsedUser.user.id);

        navigate("/dashboard"); 
        setHasNavigated(true);
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    }

    if (errorMessage) {
      alert(decodeURIComponent(errorMessage));
    }
  }, [hasNavigated, navigate]);

  // Effect to check for token and navigate if authenticated
  useEffect(() => {
    if (hasNavigated) return; // Prevent further navigation if already navigated

    const token = Cookies.get("authToken");
    if (token) {
      navigate('/dashboard'); // Navigate to dashboard if token exists
      toast.success("Login Succesfully");
      setHasNavigated(true); // Set navigation state to true
    }
  }, [hasNavigated, navigate]);

  return (
    <div className="container-scroller" style={{ height: '98vh' }}>
    <div className="row" style={{ height: '100%', backgroundImage: 'url("/assets/images/b.jpg")',backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
      <div className="col-md-6" ></div>
      <div className="col-md-6" style={{ color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <div className="card">
          <div className="card-body">
            <img src="/assets/logo/fulllogo.png" alt="Google" style={{ width: '150px' }} />
          </div>
          <div className="card-body">
            <h4>Welcome to Backoffice</h4>
            <p className="card-text">Please login with your Google account.</p>
            <button className="btn btn-primary" style={{ backgroundColor: 'red', borderColor: 'red', display: 'flex', alignItems: 'center' }} onClick={handleGoogleLogin}>
              <img src="/assets/images/g.png" alt="Google" style={{ width: '30px', marginRight: '10px' }} />
              Login with Google
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Login;
