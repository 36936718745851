import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BASE_URL, getinvoicenumber,storeinvoicenumber,allinvoice, updateInvoice } from '@Networking/APIs/NWConfig';
import Cookies from 'js-cookie';


// invoice number get
export const getInvoiceNumber = createAsyncThunk(
    'get/getInvoiceNumber',
    async () => {  
      // console.log(getinvoicenumber, "url111");   
  
      let url = BASE_URL + getinvoicenumber; 
      // console.log(url, "url");   
  
      const token = Cookies.get('authToken');
      // console.log(token,"tokenn");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        // console.log("response ", response);
        return Promise.resolve(response);
      } catch (error) {
        // console.log(error,"api error")
        return Promise.reject(error);
      }
    },
  );
  
  // GenerateInvoice
export const storeInvoice = createAsyncThunk(
    "post/storeInvoice",
    async(post) => {

        let url = BASE_URL + storeinvoicenumber; 
        // console.log("storeInvoice Called:", post, url);

        const token = Cookies.get('authToken');

        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;

            // console.log(response, " response");
                return Promise.resolve(response);
        } catch(error) {
            console.error("error:", error);
            return Promise.reject(error);
        }
    }
);

// Edit Invoice
export const EditInvoice = createAsyncThunk(
  "post/EditInvoice",
  async(post) => {

      let url = BASE_URL + updateInvoice; 
      // console.log("UpdateInvoice Called:", post, url);

      const token = Cookies.get('authToken');

      try {
        // console.log("111")
          const response = (await axios.post(url, post, {
              headers: {
                  Authorization: `Bearer ${token}`  
              }
          })).data;

          // console.log(response, " response");
              return Promise.resolve(response);
      } catch(error) {
          console.error("error:", error);
          return Promise.reject(error);
      }
  }
);

// All Invoice Data
export const getallinvoices = createAsyncThunk(
  'get/getallinvoices',
  async () => {  
    // console.log(allinvoice, "url111");   

    let url = BASE_URL + allinvoice; 
    // console.log(url, "url");   

    const token = Cookies.get('authToken');
    // console.log(token,"tokenn");
    try {
      const response = (await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })).data;
      
      // console.log("response ", response);
      return Promise.resolve(response);
    } catch (error) {
      // console.log(error,"api error")
      return Promise.reject(error);
    }
  },
);