import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, client } from "@Networking/APIs/NWConfig";
import Cookies from 'js-cookie';

// GenerateInvoice
export const postClientStore = createAsyncThunk(
    "post/postClientStore",
    async(post) => {

        let url = BASE_URL + client;
        console.log("postClientStore Called:", post, url);

        const token = Cookies.get('authToken');

        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;

            console.log(response, " response");
                return Promise.resolve(response);
        } catch(error) {
            console.error("error client error:", error);
            return Promise.reject(error);
        }
    }
);
