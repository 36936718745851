import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '@Layout/Loader/loader';
import './logincss.css';
import { useForm } from 'react-hook-form'; 

const Signup = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    const { name, email, password } = data;
    setIsLoading(true);
    setIsLoading(false);
  };

  return (
    <div className="container-scroller">
    <div className="container-fluid page-body-wrapper full-page-wrapper">
      <div className="content-wrapper d-flex align-items-center auth">
        <div className="row flex-grow">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-left p-5">
              <div className="brand-logo">
                <img src="../../assets/logo/fulllogo.png"/>
              </div>
              <h4>New here?</h4>
              <h6 className="font-weight-light">Signing up is easy. It only takes a few steps</h6>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group ">
              <label htmlFor="name" className='text-light'>Name:</label>
              <input
                id="name"
                className="form-control form-control-lg"
                placeholder="Name"
                {...register('name', { required: true })}
                required
              />
              {errors.name && <span className="error">This field is required</span>}
            </div>
            <div className="form-group  ">
              <label htmlFor="email" className='text-light'>Email:</label>
              <input
                type="email"
                className="form-control form-control-lg"
                placeholder="Email"
                id="exampleInputemail1"
                {...register('email', { required: true })}
                required
              />
              {errors.email && <span className="error">This field is required</span>}
            </div>
            <div className="form-group">
              <label htmlFor="password" className='text-light'>Password:</label>
              <input
                type="password"
                className="form-control form-control-lg"
                placeholder="Password"
                id="exampleInputPassword1"
                
                {...register('password', { required: true })}
                required
              />
              {errors.password && <span className="error">This field is required</span>}
            </div>
            <div class="mb-4">
                    <div class="form-check">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" class="form-check-input"/> I agree to all Terms & Conditions </label>
                    </div>
                  </div>
                  <div class="mt-3 d-grid gap-2">
                    <button class="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn" href="../../index.html">SIGN UP</button>
                  </div>
                  <div class="text-center mt-4 font-weight-light"> Already have an account? <Link to="/" class="text-primary">Login</Link>
                  </div>
          </form>
          </div>
            </div>
          </div>
        </div>
       
      </div>
     
    </div>
  );
};

export default Signup;