    import { createSlice } from "@reduxjs/toolkit";
    import { Logout, postlogin, postsignUp } from "@Networking/APIs/Login/loginApi";
import { toast } from "react-toastify";
    const LoginSlice = createSlice({
        name: 'LoginSlice',
        initialState: {
          loading: false,
          message: '', // Make sure this exists
          success: false,
          error: null,
          userLoginData:null,
          
        },

    extraReducers: (builder) => {
        builder.addCase(postlogin.pending, state => {
            // console.log("Pending....");
            state.loading = true
        })
        builder.addCase(postlogin.fulfilled, (state, action) => {
            console.log("Fulfilled....");
            state.loading = true;
            state.success = true;
            state.message = action.payload
            state.userLoginData = action.payload
             console.log(state.userLoginData);
            if(action.payload.status == true){
                toast.success(action.payload.message)
                
              }
              else{
                toast.error(action.payload.message)
                console.log("this is error ");
              }
            })
            //  let config = action.payload;
            
    
        builder.addCase(postlogin.rejected, state => {
            // console.log("Rejected....");
            state.loading = false
        })
        builder.addCase(Logout.pending, state => {
            // console.log("Pending....");
            state.loading = true
        })
        builder.addCase(Logout.fulfilled, (state, action) => {
            console.log("Fulfilled....");
            state.loading = true;
            state.success = true;
            state.message = action.payload
             
            if(action.payload.status == true){
                toast.success(action.payload.message)
               }
              else{
                toast.error(action.payload.message)
                console.log("this is error ");
              }
            })
            //  let config = action.payload;
            
    
        builder.addCase(Logout.rejected, state => {
            // console.log("Rejected....");
            state.loading = false
        })
        builder.addCase(postsignUp.pending, state => {
            console.log("Pending....");
            state.loading = true
        })
        builder.addCase(postsignUp.rejected, state => {
            console.log("Rejected....");
            state.loading = false
        })
    }
    })

    export default LoginSlice.reducer;