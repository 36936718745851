import { createSlice } from '@reduxjs/toolkit';
import { postClientStore } from '@Networking/APIs/Client/clientApi';
import { getClient } from '@Networking/APIs/Client/clientGetApi';
import { toast } from 'react-toastify';

const clientSlice = createSlice({
  name: 'clientSlice',
  initialState: {
    loading: false,
    message: '',
    ClientGet:{},
    success: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(postClientStore.pending, (state) => {
      // console.log("Pending....");
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postClientStore.fulfilled, (state, action) => {
      // console.log("Fulfilled....");
      state.loading = false;
      state.success = true;
      state.message = action.payload;
      console.log(state.message," state.message")
     
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
        console.log("this is error ");
      }
      
    });
    builder.addCase(postClientStore.rejected, (state, action) => {
      // console.log("Rejected....");
      state.loading = false;
      toast.error(action.payload.message)
      state.error = action.error.message || 'Login failed.';
    });

      builder.addCase(getClient.pending, (state) => {
        // console.log("Pending....");
        state.loading = true;
        state.error = null;
      });
      builder.addCase(getClient.fulfilled, (state, action) => {
        // console.log("Fulfilled....");
        state.loading = false;
        state.success = true;
        state.ClientGet = action.payload;
      });
      builder.addCase(getClient.rejected, (state, action) => {
        // console.log("Rejected....");
        state.loading = false;
        state.error = action.error.message || 'Login failed.';
      });
  },
});

export default clientSlice.reducer;