import React, { useState, useEffect } from "react";
import Select from "react-select";
import "../Invoice/invoicesForm.css";
import { IoMdPersonAdd } from "react-icons/io";
import "bootstrap/dist/css/bootstrap.min.css";
import ClientForm from "./ClientFrom/clientForm";
import ItemForm from "./ItemForm/itemForm";
import BankForm from "./Bank/bankForm";
import CompanyForm from "./Comapany/companyForm";
import Loader from "@Layout/Loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { getClient } from "@Networking/APIs/Client/clientGetApi";
import { getCompany } from "@Networking/APIs/Company/companyGetApi";
import { getCategory } from "@Networking/APIs/Category/CategoryGetApi";
import { getItem } from "@Networking/APIs/Item/getitem";
import { getBank, getindirctBank } from "@Networking/APIs/Bank/bankGetApi";
import { Tooltip } from "react-tooltip";

import {
  getInvoiceNumber,
  storeInvoice,
} from "@Networking/APIs/Invoice/invoiceApi";
import { useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CategoryForm from "./Category/CategoryForm";

import { format } from "date-fns";

const gstOptions = [
  { value: "CGST/IGST", label: "CGST/IGST", percentage: 18 },
  { value: "SGST", label: "SGST", percentage: 9 },
];

const DueDateOptions = [
  { value: 0, label: "Today (Default)" },
  { value: 3, label: "After 3 days" },
  { value: 5, label: "After 5 days" },
  { value: 7, label: "After 7 days" },
  { value: 10, label: "After 10 days" },
  { value: 15, label: "After 15 days" },
  { value: "custom-date", label: "Pick a Custom Date" },
];
const paymentOption = [
  { value: "bank", label: "Bank Transfer" },
  { value: "paypal", label: "Paypal" },
  { value: "payoneer", label: "Payoneer" },
];

const InvoiceForm = () => {
  const navigate = useNavigate();
  const { AllBankData, IndirectBank } = useSelector(
    (state) => state.BankPostSlice
  );
  const { CategoryGet } = useSelector((state) => state.CategorySlice);
  const { ClientGet } = useSelector((state) => state.clientSlice);
  const { ItemData } = useSelector((state) => state.ItemSlice);
  const { CompanyGet } = useSelector((state) => state.CompanyStoreSlice);
  const [selectedFilter, setSelectedFilter] = useState("");
  //  APi Section
  const dispatch = useDispatch();
  const [clientOptions, setClientOptions] = useState("");
  const [companyOptions, setCompanyOptions] = useState("");
  const [categoryOptions, setCaegoryOptions] = useState("");
  const [itemOptions, setItemOptions] = useState([]);
  const [bankOptions, setBankOptions] = useState("");
  // console.log(ClientGet, " ClientGet");

  const handleSelectChange = (selectedOption) => {
    if (selectedOption.value === "custom-date") {
      console.log(selectedOption.value);
      setIsCustomDateSelected(true);
    } else {
      const daysToAdd = parseInt(selectedOption.value);
      const newDueDate = new Date(
        new Date().setDate(new Date().getDate() + daysToAdd)
      );
      const formattedDate = format(newDueDate, "dd-MM-yyyy");
      console.log(formattedDate);
      // Update only the selected value's label to display the date
      setDueDate({ value: selectedOption.value, label: formattedDate });
      // Update the invoice data (if necessary)
      console.log("this is due date ", formattedDate);
      setInvoiceData((prevData) => ({ ...prevData, DueDate: formattedDate }));
    }
  };
  const handleDateChange = (date) => {
    const formattedDate = date ? date.toISOString().split("T")[0] : "";
    setDueDate(formattedDate); // Set the custom date
    setInvoiceData({ ...invoiceData, DueDate: formattedDate });
  };

  useEffect(() => {
    dispatch(getItem());
  }, [itemOptions, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const response6 = await dispatch(getInvoiceNumber());

      const response = await dispatch(getCompany());

      const response2 = await dispatch(getClient());

      const response3 = await dispatch(getCategory());

      const response4 = await dispatch(getItem());

      console.log(response4, "response4");

      const response5 = await dispatch(getBank());

      const response1 = await dispatch(getindirctBank());

      if (response6.payload) {
        setInvoiceNumber(response6.payload);

        // console.log(response6.payload, "Invoice number ");
      }

      if (response.payload.data) {
        setCompanyOptions(response.payload.data);
      }

      if (response1.payload.data) {
        setBankOptions(response1.payload.data);

        // console.log(response1.payload.data, "indirct bank all ");
      }

      if (response2.payload.data) {
        setClientOptions(response2.payload.data);
      }

      if (response3.payload.data) {
        setCaegoryOptions(response3.payload.data);
      }

      if (ItemData.data && ItemData.data != null) {
        console.log(ItemData.data, "ItemData.data");

        setItemOptions(ItemData?.data);
      }

      if (response5.payload.data) {
        setBankOptions(response5.payload.data);

        // console.log(response5.payload.data, "bank all ");
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, "0")}-${String(
      today.getMonth() + 1
    ).padStart(2, "0")}-${today.getFullYear()}`;

    setInvoiceDate(formattedDate);
    setDueDate(formattedDate);
    setProducts((prevProducts) =>
      prevProducts
        .map((product) => {
          // If showDate is true, set it to today's date
          if (product.showDate === true) {
            return { ...product, showDate: today };
          }
          return product;
        })
        .filter((product) => product.showDate !== today)
    );

    // (async function () {
    //   try {
    //     await fetchData();
    //   } catch (error) {
    //     console.error("Error in fetching company or client data:", error);
    //   }
    // })();
  }, []);
  const calculateFutureDate = (days) => {
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + days);

    const formattedFutureDate =
      ("0" + futureDate.getDate()).slice(-2) +
      "-" +
      ("0" + (futureDate.getMonth() + 1)).slice(-2) +
      "-" +
      futureDate.getFullYear();

    return formattedFutureDate;
  };

  // Function to get the options with dynamically calculated future dates
  const getDueDateOptions = () => {
    return DueDateOptions.map((option) => {
      if (option.value !== "custom-date") {
        const days = parseInt(option.value);
        const futureDate = calculateFutureDate(days);
        return { value: futureDate, label: `${option.label} ` };
      }
      return option; // Keep the "custom date" option unchanged
    });
  };
  const handleInvoiceDateChange = (date) => {
    const formattedDate =
      ("0" + date.getDate()).slice(-2) +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      date.getFullYear();
    setInvoiceDate(formattedDate);
  };

  // Local State Data
  const [template, setTemplate] = useState("amount only");
  const [currency, setCurrency] = useState("USD");
  const [Client, setClient] = useState(null);
  const [Bank, setBank] = useState(null);
  const [INdirectBank, setINdirectBank] = useState(null);
  const [Payment, setPayment] = useState(null);
  const [paypalId, setPaypalId] = useState("");
  const [payoneerId, setPayoneerId] = useState("");
  const [Company, setCompany] = useState(null);
  const [isCustomDateSelected, setIsCustomDateSelected] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [products, setProducts] = useState([
    {
      item: null,
      quantity: 0,
      hours: 0,
      price: 0,
      discountValue: 0,
      description: "",
      showDiscount: false,
      showDate: false,
      itemLabel: "",
      category: null,
      categoryLabel: "",
    },
  ]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [DueDate, setDueDate] = useState("");
  console.log("this is state wise due date", DueDate);
  const [options, setOptions] = useState(DueDateOptions);
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(0);
  const [gstType, setGstType] = useState(null);
  const [gstPercentage, setGstPercentage] = useState(0);
  const [showAddClientForm, setShowAddClientForm] = useState(false);
  const [showAddItemForm, setShowAddItemForm] = useState(false);
  const [showAddCategoryForm, setShowAddCategoryForm] = useState(false);
  const [showAddBankForm, setShowAddBankForm] = useState(false);
  const [showAddCompanyForm, setShowAddCompanyForm] = useState(false);
  const [showCustomize, setshowCustomize] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otherAmounts, setOtherAmounts] = useState([{ name: "", amount: 0 }]);
  const [showOtherAmountFields, setShowOtherAmountFields] = useState(false);
  const [showInvoicePreview, setShowInvoicePreview] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [invoiceData, setInvoiceData] = useState({
    template: "amount only",
    currency: "USD",
    Client: null,
    products: [
      {
        item: null,
        quantity: 0,
        hours: 0,
        price: 0,
        showDate: "",
        discountValue: 0,
        description: "",
      },
    ],
    Payment: null,
    INdirectBank: null,
    Bank: null,
    Company: null,
    invoiceDate: "",
    DueDate: "",
    discount: 0,
    otherAmounts: [{ name: "", amount: 0 }],
    total: 0,
  });

  // Function to handle adding a new client
  const handleAddClient = (newClient) => {
    setClientOptions([...clientOptions, newClient]);
    setClient(newClient);
  };

  // Function to handle adding a new item
  const handleAddItem = (newItem) => {
    setItemOptions([...itemOptions, newItem]);
    setProducts([...products, newItem]);
  };

  // Function to handle adding a new bank
  const handleAddBank = (newBank) => {
    setBankOptions([...bankOptions, newBank]);
    setBank(newBank);
  };

  // Function to handle adding a new company
  const handleAddCompany = (newCompany) => {
    setCompanyOptions([...companyOptions, newCompany]);
    setCompany(newCompany);
  };

  const calculateTotal = () => {
    let totalAmount = 0;
    totalAmount += products.reduce((total, product) => {
      const price = parseFloat(product.price) || 0;
      const quantity = parseInt(product.quantity, 10) || 1;
      const hours = parseInt(product.hours, 10) || 1;

      // Calculate discount directly on the price
      const discountValue = parseFloat(product.discountValue) || 0;
      const discountAmount = (discountValue / 100) * price;
      const discountedPrice = price - discountAmount;

      if (template === "amount only") {
        return total + discountedPrice;
      } else if (template === "hours") {
        return total + hours * discountedPrice;
      } else {
        return total + quantity * discountedPrice;
      }
    }, 0);

    // console.log(totalAmount, " totalAmount");
    const discountAmount = (discount / 100) * totalAmount;
    const GstAmount = (gstPercentage / 100) * totalAmount;
    var FinalAmount = totalAmount - discountAmount + GstAmount;
    FinalAmount += otherAmounts.reduce(
      (total, amount) => total + parseFloat(amount.amount) || 0,
      0
    );
    setTotal(FinalAmount);
    // console.log(FinalAmount, "calculate function calling ");
    return FinalAmount;
  };
  useEffect(() => {
    const calculatedTotal = calculateTotal();
    setTotal(calculatedTotal);
  }, [products, discount, template, gstPercentage, otherAmounts]);
  const handleTemplateChange = (e) => {
    setTemplate(e.target.value);

    if (e.target.value === "hours") {
      // console.log("hours");
      setProducts([
        {
          item: null,
          quantity: 0,
          hours: 1,
          price: 0,
          discountValue: 0,
          description: "",
          showDiscount: false,
          showDate: false,
          itemLabel: "",
          category: null,
          categoryLabel: "",
        },
      ]);
    } else if (e.target.value === "quantity") {
      setProducts([
        {
          item: null,
          quantity: 1,
          hours: 0,
          price: 0,
          discountValue: 0,
          description: "",
          showDiscount: false,
          showDate: false,
          itemLabel: "",
          category: null,
          categoryLabel: "",
        },
      ]);
    }

    setInvoiceData((prevData) => ({ ...prevData, template: e.target.value }));
  };

  const handleCurrencyChange = (e) => {
    const newCurrency = e.target.value;
    setCurrency(newCurrency);
    // Update invoiceData using a callback to access the latest currency value
    setInvoiceData((prevData) => ({ ...prevData, currency: newCurrency }));

    if (newCurrency === "INR") {
      setGstType("CGST/IGST");
    } else {
      setGstType(null);
      setGstPercentage(0);
    }
  };

  const handleClientChange = (selectedOption) => {
    if (selectedOption.value === "addClient") {
    } else {
      setClient(selectedOption);
      setInvoiceData({ ...invoiceData, Client: selectedOption });
    }
  };
  const clientModel = () => {
    setShowAddClientForm(true);
  };

  const handleBankChange = (selectedOption) => {
    if (selectedOption.value === "addBank") {
      // Logic for adding a bank
    } else {
      // Set the selected bank and branch
      setBank(selectedOption);
      // console.log(selectedOption, "selectedOption");

      // Update the invoice data with both the bank and branch
      setInvoiceData({ ...invoiceData, Bank: selectedOption });
    }
  };

  const handleINBankChange = (selectedOption) => {
    if (selectedOption.value === "addBank") {
      // Logic for adding a bank
    } else {
      // Set the selected bank and branch
      setINdirectBank(selectedOption);
      // console.log(selectedOption, "selectedOption");

      // Update the invoice data with both the bank and branch
      setInvoiceData({ ...invoiceData, INdirectBank: selectedOption });
    }
  };

  const handlePaymentChange = (selectedOption) => {
    setPayment(selectedOption);
    setInvoiceData({ ...invoiceData, Payment_mode: selectedOption });
  };

  const handlePaypalIdChange = (e) => {
    setPaypalId(e.target.value);
    setInvoiceData({ ...invoiceData, PaypalId: e.target.value });
  };

  const handlePayoneerIdChange = (e) => {
    setPayoneerId(e.target.value);
    setInvoiceData({ ...invoiceData, PayoneerId: e.target.value });
  };

  const bankModel = () => {
    setShowAddBankForm(true);
  };

  const handleCompanyChange = (selectedOption) => {
    if (selectedOption.value === "addCompany") {
    } else {
      setCompany(selectedOption);
      setInvoiceData({ ...invoiceData, Company: selectedOption });
    }
  };

  const companyModel = () => {
    setShowAddCompanyForm(true);
  };

  // const handleGstTypeChange = (selectedOption) => {
  //   setGstType(selectedOption.value);
  //   setGstPercentage(selectedOption.percentage);
  //   setInvoiceData({
  //     ...invoiceData,
  //     gstType: selectedOption.value,
  //     gstPercentage: selectedOption.percentage,
  //   });
  // };

  const handleProductChange = (index, field, value) => {
    console.log("item changeable ");
    const newProducts = [...products];
    console.log(newProducts);
    newProducts[index][field] = value;

    if (field === "item" && value) {
      const selectedItem = ItemData.data?.find((item) => item.value === value);
      if (selectedItem) {
        newProducts[index].price = selectedItem?.price;

        newProducts[index].description = selectedItem?.description;
      }
      // console.log(selectedItem, "item selected");
    }

    setProducts(newProducts);
    setInvoiceData({ ...invoiceData, products: newProducts });
  };

  const ItemModel = () => {
    setShowAddItemForm(true);
  };

  const CategoryModel = () => {
    setShowAddCategoryForm(true);
  };

  const addProduct = () => {
    setProducts([
      ...products,
      {
        item: null,
        quantity: 0,
        hours: 0,
        price: 0,
        description: "",
        showDiscount: false,
        showDate: false,
        itemLabel: "",
        category: null,
        categoryLabel: "",
      },
    ]);
  };

  const removeProduct = (index) => {
    if (products.length > 1) {
      setProducts(products.filter((_, i) => i !== index));
      setInvoiceData({
        ...invoiceData,
        products: products.filter((_, i) => i !== index),
      });
    }
  };

  const handleCheckboxChange = (index, field) => {
    const newProducts = [...products];
    newProducts[index][field] = !newProducts[index][field];
    setProducts(newProducts);
    setInvoiceData({ ...invoiceData, products: newProducts });
  };
  // const handleDiscountChange = (e) => {
  //   const discountValue = parseFloat(e.target.value) || 0;
  //   setDiscount(discountValue);
  //   setInvoiceData({ ...invoiceData, discount: discountValue });
  // };

  const handleGstPercentageChange = (e) => {
    const percentage = parseFloat(e.target.value) || 0;
    setGstPercentage(percentage);
    setInvoiceData({ ...invoiceData, gstPercentage: percentage });
  };

  const handleOtherAmountChange = (index, field, value) => {
    const newOtherAmounts = [...otherAmounts];
    newOtherAmounts[index][field] = value;
    setOtherAmounts(newOtherAmounts);
    setInvoiceData({ ...invoiceData, otherAmounts: newOtherAmounts });
  };

  const addOtherAmount = () => {
    setOtherAmounts([...otherAmounts, { name: "", amount: 0 }]);
    setInvoiceData({
      ...invoiceData,
      otherAmounts: [...otherAmounts, { name: "", amount: 0 }],
    });
  };

  const removeOtherAmount = (index) => {
    if (otherAmounts.length > 1) {
      setOtherAmounts(otherAmounts.filter((_, i) => i !== index));
      setInvoiceData({
        ...invoiceData,
        otherAmounts: otherAmounts.filter((_, i) => i !== index),
      });
    }
  };

  const toggleOtherAmountFields = () => {
    setShowOtherAmountFields(!showOtherAmountFields);
  };

  const simulateLoading = async () => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsLoading(false);
  };
  useEffect(() => {
    const total = calculateTotal();
    setInvoiceData({
      ...invoiceData,
      total,
    });
  }, [total]);

  const validateForm = () => {
    const errors = {};

    // Client Validation
    if (!Client) {
      errors.client = "Please select a client.";
    }

    //Payment Mode
    if (!Client) {
      errors.Payment = "Please select a payment.";
    }

    // Bank Validation
    if (!Bank) {
      errors.bank = "Please select a bank.";
    }

    // Company Validation
    if (!Company) {
      errors.company = "Please select a company.";
    }

    // Product Validation
    products.forEach((product, index) => {
      if (!product.item) {
        errors[`item-${index}`] = "Please select an item.";
      }
      if (!product.category) {
        errors[`category-${index}`] = "Please select a category.";
      }
      if (template === "hours" || template === "quantity") {
        if (!product.hours && template === "hours") {
          errors[`hours-${index}`] = "Please enter the hours.";
        }
        if (!product.quantity && template === "quantity") {
          errors[`quantity-${index}`] = "Please enter the quantity.";
        }
        if (!product.price) {
          errors[`price-${index}`] = "Please enter the amount.";
        }
      } else {
        if (!product.price) {
          errors[`price-${index}`] = "Please enter the amount.";
        }
      }
      if (product.description.trim() === "") {
        errors[`description-${index}`] = "Please enter a description.";
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const today = new Date();
      const formattedDate = `${String(today.getDate()).padStart(
        2,
        "0"
      )}-${String(today.getMonth() + 1).padStart(
        2,
        "0"
      )}-${today.getFullYear()}`;
      const total = calculateTotal();

      // Set invoiceDate and DueDate to today's date if not selected
      const finalInvoiceData = {
        ...invoiceData,
        total,
        invoiceDate: invoiceDate || formattedDate, // If invoiceDate is empty, set to today
        DueDate: DueDate.label || formattedDate, // If DueDate is empty, set to today
      };

      dispatch(storeInvoice(finalInvoiceData));
      console.log("Invoice Data:", finalInvoiceData);
      navigate("/InvoicesList");
      simulateLoading();
    }
  };

  const handleClosePreview = () => {
    setShowInvoicePreview(false);
  };

  const invoiceTemplates = [
    {
      src: "https://s3-alpha.figma.com/hub/file/2928891459/fdcc8cf9-5199-4ead-9b07-2d4394cf855a-cover.png",
      alt: "Invoice Template 1",
    },
    {
      src: "https://s3-alpha.figma.com/hub/file/2928891459/fdcc8cf9-5199-4ead-9b07-2d4394cf855a-cover.png",
      alt: "Invoice Template 2",
    },
    {
      src: "https://s3-alpha.figma.com/hub/file/2928891459/fdcc8cf9-5199-4ead-9b07-2d4394cf855a-cover.png",
      alt: "Invoice Template 3",
    },
    {
      src: "https://s3-alpha.figma.com/hub/file/2928891459/fdcc8cf9-5199-4ead-9b07-2d4394cf855a-cover.png",
      alt: "Invoice Template 4",
    },
  ];

  return (
    <div>
      <div className="container-fuild">
        <h2 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white me-2">
            <i className="mdi mdi-file"></i>
          </span>{" "}
          INVOICE FORM
        </h2>
        <div className="row mt-2">
          <div className="col-md-8 ">
            <div className="form-row justify-content-between">
              <div className=" pb-2">
                <p className="text-start fs-6">
                  Invoice No. {invoiceNumber ? invoiceNumber : <Loader />}
                </p>
              </div>
              <div className="form-row justify-content-around pb-2">
                <div className="col-mx-5 form-row">
                  <label className="text-dark mx-2">With GST:</label>
                  <input type="radio" name="gstOption" />
                </div>
                <div className="col-mx-5 form-row">
                  <label className="text-dark mx-2">Without GST:</label>
                  <input type="radio" name="gstOption" defaultChecked />
                </div>
              </div>
            </div>
            <div className="card mb-3 shadow">
              {/* <div className="card-header">
                <h5>Invoice Details</h5>
              </div> */}
              <div className="card-body">
                <div className="d-flex justify-content-end">
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="template-select" className="text-dark">
                          Template:
                        </label>
                        <select
                          id="template-select"
                          className="form-control form-control1"
                          value={template}
                          onChange={handleTemplateChange}
                        >
                          <option value="amount only">Amount Only</option>
                          <option value="hours">Hours</option>
                          <option value="quantity">Quantity</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="currency-select" className="text-dark">
                          Currency:
                        </label>
                        <select
                          id="currency-select"
                          className="form-control form-control1"
                          value={currency} // Add value attribute for selected option
                          onChange={handleCurrencyChange}
                        >
                          <option value="USD">USD</option>
                          <option value="EUR">EUR</option>
                          <option value="PHP">PHP</option>
                          <option value="XCD">XCD</option>
                          {/* <option value="INR">INR</option> */}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="client-select" className="text-dark">
                        INVOICE TO:
                      </label>
                      <div className="select-client-container  form-row">
                        <div className="col-1 mt-2  ">
                          <span className="input-group-text py-2">
                            <IoMdPersonAdd
                              onClick={clientModel}
                              className="add-client-icon Icon-Style"
                              data-tooltip-content="Add Client"
                              data-tooltip-id="addClientTooltip"
                            />
                            <Tooltip
                              id="addClientTooltip"
                              place="top"
                              effect="solid"
                            />
                          </span>
                        </div>
                        <div className="col-11">
                          <Select
                            id="client-select"
                            options={ClientGet.data}
                            placeholder="Select Client"
                            value={Client}
                            onChange={handleClientChange}
                            isSearchable
                            className="select-client"
                          />

                          {formErrors.client && (
                            <div className="text-danger">
                              {formErrors.client}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {Client && (
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <p className="text-primary text-center">
                        Client : {Client.label} - Company :{" "}
                        {Client.business_name} - Phone : {Client.client_phone} -
                        Email : {Client.email}
                      </p>
                    </div>
                  </div>
                )}

                {showAddClientForm && (
                  <ClientForm
                    clientOptions={clientOptions}
                    setClientOptions={setClientOptions}
                    Client={Client}
                    setClient={setClient}
                    onClose={() => setShowAddClientForm(false)}
                    onAddClient={handleAddClient}
                  />
                )}
                {products.map((product, index) => (
                  <div key={index} className="product-row p-2 mt-2">
                    <div className="row d-flex flex-row">
                      <div className="col-md-5 pt-3 m-0">
                        <label
                          htmlFor={`item-select-${index}`}
                          className="text-dark"
                        >
                          Item:
                        </label>
                        <div className="select-client-container form-row">
                          <div className="col-3 mt-2  ">
                            <span className="input-group-text py-2 ">
                              <IoMdPersonAdd
                                onClick={ItemModel}
                                className="add-client-icon Icon-Style"
                                data-tooltip-content="Add Item"
                                data-tooltip-id="addClientTooltip"
                              />
                              <Tooltip
                                id="addClientTooltip"
                                place="top"
                                effect="solid"
                              />
                            </span>
                          </div>
                          <div className="col-9">
                            <Select
                              id={`item-select-${index}`}
                              options={ItemData.data}
                              placeholder={
                                product.itemLabel
                                  ? product.itemLabel
                                  : "Select item"
                              }
                              value={product.item}
                              onChange={(selectedOption) => {
                                if (
                                  selectedOption.value.startsWith("addItem")
                                ) {
                                } else {
                                  handleProductChange(
                                    index,
                                    "item",
                                    selectedOption.value
                                  );
                                  handleProductChange(
                                    index,
                                    "itemLabel",
                                    selectedOption.label
                                  );
                                }
                              }}
                              isSearchable
                              className="select-item"
                            />
                            {formErrors[`item-${index}`] && (
                              <div className="text-danger">
                                {formErrors[`item-${index}`]}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3  pt-3 m-0">
                        <label
                          htmlFor={`category-select-${index}`}
                          className="text-dark"
                        >
                          Category:
                        </label>
                        <div className="select-client-container form-row">
                          <div className="col-4 mt-2  ">
                            <span className="input-group-text py-2">
                              <IoMdPersonAdd
                                onClick={CategoryModel}
                                className="add-client-icon Icon-Style"
                                data-tooltip-content="Add Item"
                                data-tooltip-id="addClientTooltip"
                              />
                              <Tooltip
                                id="addClientTooltip"
                                place="top"
                                effect="solid"
                              />
                            </span>
                          </div>
                          <div className="col-8">
                            <Select
                              id={`category-select-${index}`}
                              options={CategoryGet.data}
                              placeholder={
                                product.categoryLabel
                                  ? product.categoryLabel
                                  : "Category"
                              }
                              value={product.category}
                              onChange={(selectedOption) => {
                                if (
                                  selectedOption.value.startsWith("addCategory")
                                ) {
                                } else {
                                  handleProductChange(
                                    index,
                                    "category",
                                    selectedOption.value
                                  );
                                  handleProductChange(
                                    index,
                                    "categoryLabel",
                                    selectedOption.label
                                  );
                                }
                              }}
                              isSearchable
                              className="select-item"
                            />
                            {formErrors[`category-${index}`] && (
                              <div className="text-danger">
                                {formErrors[`category-${index}`]}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* Show Hour/Quantity or Amount based on template */}
                      {template === "hours" || template === "quantity" ? (
                        <>
                          <div className="col-2 pt-3 m-0">
                            <label
                              htmlFor={`quantity-hours-${index}`}
                              className="text-dark"
                            >
                              {template === "hours" ? "Hours:" : "Quantity:"}
                            </label>
                            <input
                              id={`quantity-hours-${index}`}
                              className="form-control form-control1 mt-2"
                              style={{ width: "100%" }}
                              type="number"
                              placeholder={
                                template === "hours" ? "Hours" : "Quantity"
                              }
                              value={
                                template === "hours"
                                  ? product.hours
                                  : product.quantity
                              }
                              onChange={(e) =>
                                handleProductChange(
                                  index,
                                  template === "hours" ? "hours" : "quantity",
                                  e.target.value
                                )
                              }
                            />
                            {formErrors[`hours-${index}`] && (
                              <div className="text-danger">
                                {formErrors[`hours-${index}`]}
                              </div>
                            )}
                            {formErrors[`quantity-${index}`] && (
                              <div className="text-danger">
                                {formErrors[`quantity-${index}`]}
                              </div>
                            )}
                          </div>
                          <div className="col-md-2 pt-3 ">
                            <label
                              htmlFor={`price-${index}`}
                              className="text-dark "
                            >
                              Amount:
                            </label>
                            <input
                              id={`price-${index}`}
                              type="number"
                              placeholder="Amount"
                              style={{ width: "100%" }}
                              value={product.price}
                              onChange={(e) =>
                                handleProductChange(
                                  index,
                                  "price",
                                  e.target.value
                                )
                              }
                              className="form-control form-control1 mt-2"
                            />
                            {formErrors[`price-${index}`] && (
                              <div className="text-danger">
                                {formErrors[`price-${index}`]}
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="col-md-4 pt-3 m-0">
                          <label
                            htmlFor={`price-${index}`}
                            className="text-dark"
                          >
                            Amount:
                          </label>
                          <input
                            id={`price-${index}`}
                            type="number"
                            placeholder="Amount"
                            style={{ width: "100%" }}
                            value={product.price}
                            onChange={(e) =>
                              handleProductChange(
                                index,
                                "price",
                                e.target.value
                              )
                            }
                            className="form-control form-control1 mt-2"
                          />
                          {formErrors[`price-${index}`] && (
                            <div className="text-danger">
                              {formErrors[`price-${index}`]}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {(product.showDiscount || product.showDate) && (
                      <div className="form-row justify-content-between">
                        {product.showDiscount && (
                          <div className="col-5 form-control1">
                            <label htmlFor={`discount-${index}`}>
                              Discount:
                            </label>
                            <input
                              id={`discount-${index}`}
                              type="text"
                              placeholder="Discount"
                              style={{ width: "100%" }}
                              className="form-control form-control1 mt-2"
                              value={product.discountValue}
                              onChange={(e) =>
                                handleProductChange(
                                  index,
                                  "discountValue",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        )}
                        {product.showDate && (
                          <div className="col-5 form-control1">
                            <label htmlFor={`date-${index}`}>Date:</label>
                            <input
                              id={`date-${index}`}
                              type="date"
                              placeholder="Date"
                              className="form-control form-control1 mt-2"
                              style={{ width: "100%" }}
                              value={product.date}
                              onChange={(e) =>
                                handleProductChange(
                                  index,
                                  "date",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        )}
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-12 p-3 form-group form-control1">
                        <label
                          htmlFor={`description-${index}`}
                          className="text-dark"
                        >
                          Description:
                        </label>
                        <textarea
                          id={`description-${index}`}
                          type="text"
                          className="form-control form-control1 "
                          placeholder="Description"
                          value={product.description}
                          onChange={(e) =>
                            handleProductChange(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                        />
                        {formErrors[`description-${index}`] && (
                          <div className="text-danger">
                            {formErrors[`description-${index}`]}
                          </div>
                        )}
                      </div>
                    </div>
                    {showCustomize ? (
                      <div className="form-row d-flex justify-content-start">
                        <div className="d-flex">
                          <a
                            className="text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setshowCustomize(false);
                            }}
                          >
                            customize
                          </a>
                        </div>
                        <div className="col-md-2 ">
                          <label className="text-dark mx-2">Discount:</label>
                          <input
                            type="checkbox"
                            checked={product.showDiscount}
                            onChange={(e) => {
                              // console.log(e.target.value, "e");
                              handleCheckboxChange(index, "showDiscount");
                            }}
                          />
                        </div>
                        <div className="col-md-2">
                          <label className="text-dark mx-2">Date:</label>
                          <input
                            type="checkbox"
                            checked={product.showDate}
                            onChange={() =>
                              handleCheckboxChange(index, "showDate")
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <a
                          className="text-primary"
                          onClick={() => {
                            setshowCustomize(true);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          customize
                        </a>
                      </div>
                    )}

                    {index !== 0 && (
                      <div className="form-group">
                        <button
                          className="remove-product-button"
                          onClick={() => removeProduct(index)}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                ))}

                <div className="row mb-3">
                  <div className="col-md-12 mt-2">
                    <button
                      type="button"
                      className="btn btn-gradient-info"
                      onClick={addProduct}
                    >
                      Add Product
                    </button>
                    {/* Add the checkboxes here */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 my-5">
            <div className="card mb-3 shadow">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="bank-select" className="text-dark">
                        Payment Mode:
                      </label>
                      <div className="select-client-container form-row">
                        <div className="col-12">
                          <Select
                            id="PaymentMode-select"
                            options={paymentOption}
                            placeholder="Select Payment Mode"
                            value={Payment}
                            onChange={handlePaymentChange}
                            isSearchable
                            className="select-client"
                          />
                          {formErrors.Payment && (
                            <div className="text-danger">
                              {formErrors.Payment}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {Payment && Payment.value === "bank" ? (
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="bank-select" className="text-dark">
                          Select Bank:
                        </label>
                        <div className="select-client-container form-row">
                          <div className="col-2 mt-2  ">
                            <span className="input-group-text py-2">
                              <IoMdPersonAdd
                                onClick={bankModel}
                                className="add-client-icon Icon-Style"
                                data-tooltip-content="Add Bank"
                                data-tooltip-id="addClientTooltip"
                              />
                              <Tooltip
                                id="addClientTooltip"
                                place="top"
                                effect="solid"
                              />
                            </span>
                          </div>
                          <div className="col-10">
                            <Select
                              id="bank-select"
                              options={AllBankData.data}
                              placeholder="Select Bank"
                              value={Bank}
                              onChange={handleBankChange}
                              isSearchable
                              className="select-client"
                            />
                            {formErrors.bank && (
                              <div className="text-danger">
                                {formErrors.bank}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {Bank && Payment.value === "bank" && (
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <p className="text-primary text-center">
                        Bank: {Bank.label}
                      </p>
                      <p className="text-primary text-center">
                        Branch: {Bank.branch}
                      </p>
                      <p className="text-primary text-center">
                        Account Number: {Bank.account_number}
                      </p>
                      <p className="text-primary text-center">
                        Ifsc Code: {Bank.ifsc_code}
                      </p>
                    </div>
                  </div>
                )}

                {showAddBankForm && (
                  <BankForm
                    bankOptions={bankOptions}
                    setBankOptions={setBankOptions}
                    Bank={Bank}
                    setBank={setBank}
                    onClose={() => setShowAddBankForm(false)}
                    onAddBank={handleAddBank}
                  />
                )}
                {Payment && Payment.value === "paypal" ? (
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="Paypal-select" className="text-dark">
                          Paypal ID:
                        </label>
                        <div className="select-client-container form-row">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control px-3 mt-2"
                              id="Paypal"
                              name="Paypal"
                              placeholder="Enter PayPal ID"
                              value={paypalId}
                              onChange={handlePaypalIdChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="bank-select" className="text-dark">
                          Select Bank:
                        </label>
                        <div className="select-client-container form-row">
                          <div className="col-2 mt-2  ">
                            <span className="input-group-text py-2">
                              <IoMdPersonAdd
                                onClick={bankModel}
                                className="add-client-icon Icon-Style"
                                data-tooltip-content="Add Bank"
                                data-tooltip-id="addClientTooltip"
                              />
                              <Tooltip
                                id="addClientTooltip"
                                place="top"
                                effect="solid"
                              />
                            </span>
                          </div>
                          <div className="col-10">
                            <Select
                              id="bank-select"
                              options={AllBankData.data}
                              placeholder="Select Bank"
                              value={Bank}
                              onChange={handleBankChange}
                              isSearchable
                              className="select-client"
                            />
                            {formErrors.bank && (
                              <div className="text-danger">
                                {formErrors.bank}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {Payment && Payment.value === "payoneer" ? (
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="bank-select" className="text-dark">
                          Select Payoneer Bank:
                        </label>
                        <div className="select-client-container form-row">
                          <div className="col-12">
                            <Select
                              id="bank-select"
                              options={IndirectBank.data}
                              placeholder="Select Bank"
                              value={INdirectBank}
                              onChange={handleINBankChange}
                              isSearchable
                              className="select-client "
                            />
                            {formErrors.IndirectBank && (
                              <div className="text-danger">
                                {formErrors.IndirectBank}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="Payoneer-select" className="text-dark">
                          Payoneer ID:
                        </label>
                        <div className="select-client-container form-row">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control px-3 mt-2"
                              id="Payoneer"
                              name="Payoneer"
                              placeholder="Enter Payoneer ID.."
                              value={payoneerId}
                              onChange={handlePayoneerIdChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="bank-select" className="text-dark">
                          Select Bank:
                        </label>
                        <div className="select-client-container form-row">
                          <div className="col-2 mt-2  ">
                            <span className="input-group-text py-2">
                              <IoMdPersonAdd
                                onClick={bankModel}
                                className="add-client-icon Icon-Style"
                                data-tooltip-content="Add Bank"
                                data-tooltip-id="addClientTooltip"
                              />
                              <Tooltip
                                id="addClientTooltip"
                                place="top"
                                effect="solid"
                              />
                            </span>
                          </div>
                          <div className="col-10">
                            <Select
                              id="bank-select"
                              options={AllBankData.data}
                              placeholder="Select Bank"
                              value={Bank}
                              onChange={handleBankChange}
                              isSearchable
                              className="select-client"
                            />
                            {formErrors.bank && (
                              <div className="text-danger">
                                {formErrors.bank}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="card mb-3 shadow">
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="company-select" className="text-dark">
                    INVOICE BY:
                  </label>
                  <div className="select-client-container form-row">
                    <div className="col-2 mt-2  ">
                      <span className="input-group-text py-2">
                        <IoMdPersonAdd
                          onClick={companyModel}
                          className="add-client-icon Icon-Style"
                          data-tooltip-content="Add Company"
                          data-tooltip-id="addClientTooltip"
                        />
                      </span>
                      <Tooltip
                        id="addClientTooltip"
                        place="top"
                        effect="solid"
                      />
                    </div>
                    <div className="col-10">
                      <Select
                        id="company-select"
                        options={CompanyGet.data}
                        placeholder="Select Company"
                        value={Company}
                        onChange={handleCompanyChange}
                        isSearchable
                        className="select-client"
                      />
                      {formErrors.company && (
                        <div className="text-danger">{formErrors.company}</div>
                      )}
                    </div>
                  </div>
                </div>

                {Company && (
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <p className="text-primary text-center">
                        Comapany: {Company.label}
                      </p>
                      <p className="text-primary text-center">
                        Comapany Contact: {Company.company_contact}
                      </p>
                      <p className="text-primary text-center">
                        Company Email: {Company.company_email}
                      </p>
                      <p className="text-primary text-center">
                        Comapany Website: {Company.company_website}
                      </p>
                    </div>
                  </div>
                )}

                {showAddCompanyForm && (
                  <CompanyForm
                    companyOptions={companyOptions}
                    setCompanyOptions={setCompanyOptions}
                    Company={Company}
                    setCompany={setCompany}
                    onClose={() => setShowAddCompanyForm(false)}
                    onAddCompany={handleAddCompany}
                  />
                )}
              </div>
            </div>
            <div className="card mb-3 shadow">
              {/* <div className="card-header">
                <h5>Billing</h5>
              </div> */}
              <div className="card-body">
                {/* <div className="row mb-3">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="invoice-number">Invoice Number:</label>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <input
                      id="invoice-number"
                      type="text"
                      className="form-control"
                      placeholder="Invoice Number"
                      value={invoiceNumber}
                      onChange={(e) => {
                        setInvoiceNumber(e.target.value);
                        setInvoiceData({
                          ...invoiceData,
                          invoiceNumber: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div> */}

                <div className="row justify-content-between col-12 mb-3">
                  <div className="col-md-4 ">
                    <div className="form-group">
                      <label htmlFor="invoice-date">Invoice Date:</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <ReactDatePicker
                      selected={
                        invoiceDate
                          ? new Date(invoiceDate.split("-").reverse().join("-"))
                          : null
                      }
                      onChange={handleInvoiceDateChange}
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="row justify-content-between col-12 mb-3">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="due-date">Due Date:</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* Render Select or DatePicker based on the isCustomDateSelected state */}
                    {!isCustomDateSelected ? (
                      <Select
                        id="due-date"
                        options={options} // Predefined options and custom date picker option
                        value={DueDate} // Selected value
                        onChange={handleSelectChange} // Handle selection
                        className="select-due-date"
                      />
                    ) : (
                      <ReactDatePicker
                        selected={DueDate ? new Date(DueDate) : null} // Selected date for the date picker
                        onChange={handleDateChange} // Handle date picker change
                        dateFormat="dd-MM-yyyy" // Format of the date
                        placeholderText="Select a custom due date" // Placeholder text
                        className="form-control" // Styling for the date picker
                      />
                    )}
                  </div>
                </div>

                <div className="row justify-content-between col-12 mb-3">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="discount">Discount (%):</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <input
                      id="discount"
                      type="text"
                      className="form-control form-control1"
                      placeholder="Discount (%)"
                      value={discount}
                      onChange={(e) => {
                        setDiscount(e.target.value);
                        setInvoiceData({
                          ...invoiceData,
                          discount: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                {/* 
                {currency === "INR" && (
                  <>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="gst-type">GST Type:</label>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <Select
                          id="gst-type"
                          options={gstOptions}
                          value={gstOptions.find(
                            (option) => option.value === gstType
                          )}
                          onChange={handleGstTypeChange}
                          className="select-gst-type"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="gst-percentage">
                            GST Percentage:
                          </label>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <input
                          id="gst-percentage"
                          type="number"
                          className="form-control"
                          placeholder="GST Percentage"
                          value={gstPercentage}
                          onChange={handleGstPercentageChange}
                        />
                      </div>
                    </div>
                  </>
                )} */}

                <div className="row justify-content-between col-12 mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="other-amount">Other Amount:</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <button
                      className="btn btn-gradient-info btn-sm"
                      onClick={toggleOtherAmountFields}
                    >
                      Add
                    </button>
                  </div>
                </div>

                {showOtherAmountFields && (
                  <div>
                    {otherAmounts.map((otherAmount, index) => (
                      <div key={index} className="form-row other-amount-row">
                        <div className="form-group">
                          <label htmlFor={`other-amount-name-${index}`}>
                            Name:
                          </label>
                          <input
                            id={`other-amount-name-${index}`}
                            type="text"
                            className="form-control"
                            placeholder="Enter Name"
                            value={otherAmount.name}
                            onChange={(e) =>
                              handleOtherAmountChange(
                                index,
                                "name",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor={`other-amount-${index}`}>
                            Amount:
                          </label>
                          <input
                            id={`other-amount-${index}`}
                            type="number"
                            className="form-control"
                            placeholder="Enter Amount"
                            value={otherAmount.amount}
                            onChange={(e) =>
                              handleOtherAmountChange(
                                index,
                                "amount",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        {index !== 0 && (
                          <div className="form-group">
                            <button
                              className="btn btn-sm btn-danger remove-other-amount-button"
                              onClick={() => removeOtherAmount(index)}
                            >
                              Remove
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                    <div className="form-group">
                      <button
                        className="btn btn-sm btn-gradient-info"
                        onClick={addOtherAmount}
                      >
                        Add More
                      </button>
                    </div>
                  </div>
                )}

                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="form-group">
                      <strong>
                        Total: {total.toFixed(2)} {currency}
                      </strong>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <button
                    className="btn btn-gradient-info"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Submit Invoice
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showAddItemForm && (
          <ItemForm
            itemOptions={itemOptions}
            setItemOptions={setItemOptions}
            onClose={() => setShowAddItemForm(false)}
            onAddClient={handleAddItem}
          />
        )}
        {showAddCategoryForm && (
          <CategoryForm onClose={() => setShowAddCategoryForm(false)} />
        )}
        {isLoading && <Loader />}
        {/* Modal for displaying invoice details */}
        {showInvoicePreview && (
          <div
            className="modal fade show"
            style={{ display: "block", backdropFilter: "blur(5px)" }}
            aria-modal="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Invoice Preview</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleClosePreview}
                  >
                    <span aria-hidden="true"></span>
                  </button>
                </div>
                <div className="modal-body">
                  {/* Display the preview of your invoice templates */}
                  <div className="row">
                    {invoiceTemplates.map((template, index) => (
                      <div key={index} className="col-md-3 mb-3">
                        <img
                          src={template.src}
                          alt={template.alt}
                          className="img-fluid"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceForm;
