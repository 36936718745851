import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BASE_URL, categoriesGet, categories} from '@Networking/APIs/NWConfig';
import Cookies from 'js-cookie';


export const getCategory = createAsyncThunk(
    'get/getCategory',
    async () => {  // categories should be passed as an argument
      // console.log(getCategory, "url111");   
  
      // Using template literals to build the URL
      let url = BASE_URL + categoriesGet;  // Corrected line
      // console.log(url, "url");   
  
      const token = Cookies.get('authToken');
      // console.log(token,"tokenn");
      try {
        const response = (await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        })).data;
        
        // console.log("response ", response);
        return Promise.resolve(response);
      } catch (error) {
        // console.log(error,"api error")
        return Promise.reject(error);
      }
    },
  );
  

  export const CategoryStore = createAsyncThunk(
    "post/CategoryStore",
    async(post) => {
        let url = BASE_URL + categories;
        // console.log("CategoryStore Called:", post, url);

        const token = Cookies.get('authToken');

        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;

            // console.log(response, " response");
                return Promise.resolve(response);
        } catch(error) {
            console.error("error:", error);
            return Promise.reject(error);
        }
    }
);