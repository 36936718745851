import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from "component/LoginForm/Login";

import Dashboard from "component/Dashboard/Dashboard";
import { Mainlayout } from "./Layout/mainLayout";
import InvoiceForm from "component/Invoice/InvoiceForm";

import VerifyCode from "component/LoginForm/VerifyCode";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProtectedRoute , PublicRoute  } from "component/ProtectedRoute/protectedRoute";
import Signup from "component/LoginForm/signUp";
import MultiStepForm from "component/Employee/employeeForm";
import InvoiceList from "component/Invoice/InvoiceList";
import { ProtectedOtp } from "component/ProtectedRoute/ProtectedOtp";
import PdfComponent from "component/PDF/pdfView";
import Loader from "@Layout/Loader/loader";
import Profile from "component/Profile/profile";
import InvoiceEdit from "component/Invoice/invoiceEdit";
import { InvoicePartiallyForm } from "component/Invoice/partiallyPaidForm";

const AppContent=()=> {
   

  
  return (
    <>
   
      <Routes>
      
        {/* Public routes */}
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        

        {/* <Route element={<ProtectedOtp/>}>
          <Route path="/otp" element={<VerifyCode />} />
        </Route> */}
        {/* Protected routes */}
          <Route element={<ProtectedRoute   />}>
                <Route path="/dashboard" element={<Mainlayout><Dashboard /></Mainlayout>} />
                <Route path="/Profile" element={<Mainlayout><Profile /></Mainlayout>} />
               
          </Route>
          <Route element={<ProtectedRoute  allowedRoles={['1', '2']}/>}>
              <Route path="/InvoicesForm" element={<Mainlayout><InvoiceForm /></Mainlayout>} />
                <Route path="/InvoicesList" element={<Mainlayout><InvoiceList /></Mainlayout>} />
                <Route path="/InvoiceEdit" element={<Mainlayout><InvoiceEdit /></Mainlayout>} />
                <Route path="/InvoicePartially" element={<Mainlayout><InvoicePartiallyForm /></Mainlayout>} />
                <Route path="/PDF" element={<PdfComponent  />} />
        </Route>
      </Routes>
    </>
  );
}

const App=()=> {
  return (
    <BrowserRouter>
      <AppContent />
        <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
