import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserData } from "@Networking/APIs/Background/backgorundApi";
import Loader from "@Layout/Loader/loader";
import Cookies from 'js-cookie';

const SideBar = () => {
  const dispatch = useDispatch();

 
  const userData = useSelector((state) => state.BackgroundSlice.userData);

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  const name = userData?.name;
  const role = userData?.role_id;
  const Role = Cookies.get('role');

  if (!userData) {
    return <Loader/>; 
  }

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        {/* User Profile Section */}
        <li className="nav-item nav-profile">
          <Link to="/Profile" className="nav-link">
            <div className="nav-profile-image">
              <img src="assets/images/faces/face1.jpg" alt="profile" />
              <span className="login-status online"></span>
            </div>
            <div className="nav-profile-text d-flex flex-column">
              <span className="font-weight-bold mb-2">{name}</span>
              <span className="text-secondary text-small">{Role}</span>
            </div>
            <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
          </Link>
        </li>

        {/* Dashboard Link */}
        <li className="nav-item">
          <Link className="nav-link" to="/dashboard">
            <span className="menu-title">Dashboard</span>
            <i className="mdi mdi-home menu-icon"></i>
          </Link>
        </li>

        {/* Role-based Menu Rendering */}
        {(role === "1" || role === "2" || role === "3" || role === "4") &&(
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
              <span className="menu-title">Invoices</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            </a>
            <div className="collapse" id="ui-basic">
              <ul className="nav flex-column sub-menu">
                {(role === "2" || role === "3" || role === "1") &&(
                <li className="nav-item">
                  <Link className="nav-link" to="/InvoicesForm">Create Invoice</Link>
                </li>
                )}
                <li className="nav-item">
                  <Link className="nav-link" to="/InvoicesList">Invoice List</Link>
                </li>
                
              </ul>
            </div>
          </li>
        )}

        {/* Repeat the same for other roles */}
        {/* Manager Menu */}
        {role === "2"  && (
         <li className="nav-item">
         <Link className="nav-link" to="/dashboard">
           <span className="menu-title">Employee</span>
           <i className="mdi mdi-home menu-icon"></i>
         </Link>
       </li>
       
        )}
        {/* Team Leader Menu */}
        {(role === "2"  || role === "3" || role === "4" ) &&(
         <li className="nav-item">
         <Link className="nav-link" to="/dashboard">
           <span className="menu-title">Projects </span>
           <i className="mdi mdi-home menu-icon"></i>
         </Link>
       </li>
       
       
        )}
        {/* Employee Menu */}
        {role === "2"  || role === "3"  || role === "4"  && (
          <li className="nav-item">
          <Link className="nav-link" to="/dashboard">
            <span className="menu-title">Apply Leave</span>
            <i className="mdi mdi-home menu-icon"></i>
          </Link>
        </li>
        )}
      </ul>
    </nav>
  );
};

export default SideBar;
