import React, { useState } from "react";
import "../../Invoice/invoicesForm.css";
import { useDispatch } from "react-redux";
import { CategoryStore, getCategory } from "@Networking/APIs/Category/CategoryGetApi";

export default function CategoryForm({
    onClose,
}) {
    const dispatch = useDispatch();
    const [category, setCategory] = useState("");
  
  
    const handleCategoryChange = (e) => setCategory(e.target.value);
 
  
    const handleSubmit = async (e) => {
      e.preventDefault();
    
      const newCategory = {
        name: category,
      };
    
      try {
        await dispatch(CategoryStore(newCategory));
    
        await dispatch(getCategory());
    
        onClose();
      } catch (error) {
        console.error("Error in submitting category:", error);
      }
    };

  return (
    <div>
         <div className="modal fade show"
      style={{ display: "block", backdropFilter: "blur(5px)" }}
      aria-modal="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header"
          style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src="assets/logo/tslogo.png"
                alt="Client"
                style={{ width: "25px", height: "100%",  }}
              />
            <h5 className="modal-title">Add Category</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            >
              <span aria-hidden="true"></span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="Category"
                  name="category"
                  placeholder="Add Category"
                  value={category}
                  onChange={handleCategoryChange}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
