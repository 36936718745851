import React, { useState } from 'react';
import { Navbar } from './Navnbar/navbar';
import SideBar from './SideBar/sidebar';
import Loader from "@Layout/Loader/loader";

export const Mainlayout = ({ children }) => {

  return (
    <>
    <div className="container-scroller">
    
      <Navbar />
      <div className="page-body-wrapper">
        <SideBar />
        <div className="main-panel">
          <div className="content-wrapper">
          {children}
        </div>
        </div>
      </div>
    </div>
    </>
  );
};
