
import { getPDFData } from '@Networking/APIs/PDF/pdf';
import { createSlice } from '@reduxjs/toolkit';

const PDFSlice = createSlice({
  name: 'PDFSlice',
  initialState: {
    loading: false,
    PDFData: [],
    success: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPDFData.pending, (state) => {
        console.log("Pending....");
        state.loading = true;
        state.error = null; // Reset error on new request
      })
      .addCase(getPDFData.fulfilled, (state, action) => {
        console.log("Fulfilled....");
        state.loading = false; // Set loading to false
        state.PDFData = action.payload; // Store the fetched data
        state.success = true; // Mark success
        console.log(state.PDFData, " state.PDFData");
      })
      .addCase(getPDFData.rejected, (state, action) => {
        console.log("Rejected....");
        state.loading = false; // Set loading to false
        state.error = action.payload; // Store the error
      });
  },
});

export default PDFSlice.reducer;
