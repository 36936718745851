import React from "react";
import "./loader.css";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <div className="loader-circle"></div>
        <div className="loader-circle"></div>
        <div className="loader-circle"></div>
      </div>
    </div>
  );
};

export default Loader;
                               
// import React from 'react';
// import { TailSpin } from 'react-loader-spinner';

// const Loader = () => {
//   return (
//     <div style={styles.loaderContainer}>
//       <TailSpin height="80" width="80" color="blue" ariaLabel="loading" />
//     </div>
//   );
// };

// const styles = {
//   loaderContainer: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh',
//   },
// };

// export default Loader;


