import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, PDFData } from '@Networking/APIs/NWConfig';
import Cookies from 'js-cookie';

export const getPDFData = createAsyncThunk(
    'getPDFData',
    async (ID, { rejectWithValue }) => {  
      let url = `${BASE_URL}${PDFData}/${ID}`; 
      console.log(url, "url");   
  
      const token = Cookies.get('authToken');

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`  
          }
        });
        
        console.log("response ", response.data); // Check the structure of this data
        if (response.data) {
           console.log(response.data, 'hello') 
        return response?.data?.invoice; // No need for Promise.resolve

        }
        return response?.data?.invoice; // No need for Promise.resolve

      } catch (error) {
        console.error("API error", error);
        // Return a more informative error message
        // return rejectWithValue(error.response?.data || error.message);
      }
    },
);
