import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, banks } from "@Networking/APIs/NWConfig";
import Cookies from 'js-cookie';

// GenerateInvoice
export const postBankStore = createAsyncThunk(
    "post/postBankStore",
    async(post) => {

        let url = BASE_URL + banks; 
        // console.log("postBankStore Called:", post, url);

        const token = Cookies.get('authToken');

        try {
            const response = (await axios.post(url, post, {
                headers: {
                    Authorization: `Bearer ${token}`  
                }
            })).data;

            // console.log(response, " response");
                return Promise.resolve(response);
        } catch(error) {
            console.error("error:", error);
            return Promise.reject(error);
        }
    }
);
