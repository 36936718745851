import { createSlice } from '@reduxjs/toolkit';
import { getInvoiceNumber ,storeInvoice,getallinvoices, EditInvoice} from '@Networking/APIs/Invoice/invoiceApi';
import { toast } from 'react-toastify';
 
const GetInSlice = createSlice({
  name: 'GetInSlice',
  initialState: {
    loading: false,
    InvoiceNumberGet:'',
    InvoiceData:{},
    updateInvoices:{},
    success: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(getInvoiceNumber.pending, (state) => {
      // console.log("Pending....");
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getInvoiceNumber.fulfilled, (state, action) => {
      // console.log("Fulfilled....");
      state.loading = false;
      state.success = true;
      state.InvoiceNumberGet = action.payload;
      console.log(state.InvoiceNumberGet ," state.InvoiceNumberGet")
    });
    builder.addCase(getInvoiceNumber.rejected, (state, action) => {
      // console.log("Rejected....");
      state.loading = false;
      state.error = action.error.message || 'Login failed.';
    });
    builder.addCase(storeInvoice.pending, (state) => {
        // console.log("Pending....");
        state.loading = true;
        state.error = null;
      });
      builder.addCase(storeInvoice.fulfilled, (state, action) => {
        // console.log("Fulfilled....");
        state.loading = false;
        state.success = true;
        state.InvoiceData = action.payload;
        if(action.payload.status == true){
          toast.success(action.payload.message)
        }
        else{
          toast.error(action.payload.message)
          console.log("this is error ");
        }
        console.log(state.InvoiceData ," state.InvoiceData")
      });
      builder.addCase(storeInvoice.rejected, (state, action) => {
        // console.log("Rejected....");
        state.loading = false;
        state.error = action.error.message || 'Login failed.';
      });
      builder.addCase(getallinvoices.pending, (state) => {
        // console.log("Pending....");
        state.loading = true;
        state.error = null;
      });
      builder.addCase(getallinvoices.fulfilled, (state, action) => {
        // console.log("Fulfilled....");
        state.loading = false;
        state.success = true;
        state.AllInvoices = action.payload;
        console.log(state.AllInvoices ," state.AllInvoices")
      });
      builder.addCase(getallinvoices.rejected, (state, action) => {
        // console.log("Rejected....");
        state.loading = false;
        state.error = action.error.message || 'Login failed.';
      });
      builder.addCase(EditInvoice.pending, (state) => {
        // console.log("Pending....");
        state.loading = true;
        state.error = null;
      });
      builder.addCase(EditInvoice.fulfilled, (state, action) => {
        // console.log("Fulfilled....");
        state.loading = false;
        state.success = true;
        state.updateInvoices = action.payload;
        console.log(state.updateInvoices ," updateInvoices")
      });
      builder.addCase(EditInvoice.rejected, (state, action) => {
        // console.log("Rejected....");
        state.loading = false;
        state.error = action.error.message || 'Login failed.';
      });
  },
});

export default GetInSlice.reducer;