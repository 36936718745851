import React, { useEffect, useState } from "react";
import Select from "react-select";
import "../../Invoice/invoicesForm.css";
import { useDispatch, useSelector } from "react-redux";
import { postClientStore } from "@Networking/APIs/Client/clientApi";
import { getcity, getcountries, getcountryCode, getstate } from "@Networking/APIs/Background/backgorundApi";
import { useForm } from "react-hook-form";
import { getClient } from "@Networking/APIs/Client/clientGetApi";


  const ClientForm = ({ clientOptions, setClientOptions, onClose }) => {
  const { country , state, city ,countryCode} = useSelector((state) => state.BackgroundSlice || {});
  // console.log(country, " country");

  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountryCode, setselectedCodeCountry] = useState(null);


  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption ? selectedOption.label : "");
    // Dispatch the getstate thunk with the selected country ID
    if (selectedOption) {
      dispatch(getstate(selectedOption.value)); 
    }
  };
  const handleCountryCodeChange = (selectedOption) => {
    setselectedCodeCountry(selectedOption? selectedOption.label : "");
  };

  const handleStateChange = (newValue) => {
    setSelectedState(newValue);
    // If you need to fetch cities after a state change, do it here:
    if (newValue) {
      dispatch(getcity(newValue.value));
    }
  };

  const handleCityChange = (newValue) => {
    setSelectedCity(newValue);
  };

  useEffect(() => {
    dispatch(getcountries());
    dispatch(getcountryCode())
  }, []);

  const onSubmit = async(data) => {
    const newClient = {
      first_name: data.clientName,
      last_name: data.clientLastName,
      business_name: data.clientBussinessName,
      client_phone: data.clientPhone,
      phone_code: selectedCountryCode? selectedCountryCode : "",
      email: data.clientEmail,
      address: data.clientAddress,
      gst: data.clientGst,
      pan: data.clientPAN,
      pincode: data.clientZip,
      website: data.clientWebsite,
      country: selectedCountry ? selectedCountry : "",
      state: selectedState ? selectedState.label : "",
      city: selectedCity ? selectedCity.label : "",
    };
  try {
    await dispatch(postClientStore(newClient));
    await dispatch(getClient());

    onClose();
  } catch (error) {
    console.error("Error in submitting item:", error);
  }
};

return (
  <div
    className="modal fade show"
    style={{ display: "block", backdropFilter: "blur(5px)" }}
    aria-modal="true"
  >
    <div className="modal-dialog modal-lg">
    <div className="modal-content" style={{ maxHeight: "90vh", overflow: "hidden" }}>
        <div
          className="modal-header"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src="assets/logo/tslogo.png"
              alt="Client"
              style={{ width: "25px", height: "100%" }}
            />
            <h5 className="modal-title text-center">Customer Information</h5>
          </div>
          <button
            type="button"
            className="btn-close"
            onClick={onClose}
            style={{ position: "absolute", right: "10px", top: "10px" }}
          >
            <span aria-hidden="true"></span>
          </button>
        </div>

        <div className="modal-body card-body-scrollable">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h6>Customer Information</h6>
            <div className="form-row">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control px-3 ${
                      errors.clientName ? "is-invalid" : ""
                    }`}
                  id="clientName"
                  name="clientName"
                  placeholder="First Name"
                  {...register("clientName", { required: true })}
                />
                {errors.clientName && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control px-3 ${
                      errors.clientLastName ? "is-invalid" : ""
                    }`}
                  id="clientLastName"
                  name="clientLastName"
                  placeholder="Last Name"
                  {...register("clientLastName", { required: true })}
                />
                {errors.clientLastName && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <input
                type="text"
                className={`form-control px-3 ${
                      errors.clientBussinessName ? "is-invalid" : ""
                    }`}
                id="clientBussinessName"
                name="clientBussinessName"
                placeholder="Enter Business Name"
                {...register("clientBussinessName", { required: true })}
              />
              {errors.clientBussinessName && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
            <div className="form-group">
              <input
                type="email"
                className={`form-control px-3 ${
                      errors.clientEmail ? "is-invalid" : ""
                    }`}
                id="clientEmail"
                name="clientEmail"
                placeholder="Email Address"
                {...register("clientEmail", { required: true })}
              />
              {errors.clientEmail && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
            <div className="form-row">
              <div className="form-group">
                <div className="select-client-container">
                  <Select
                    id="countryCode"
                    options={countryCode}
                    placeholder={selectedCountryCode || "Select Country Code"}
                    {...register("phoneCode")}
                    isSearchable
                    className="select-client"
                    onChange={handleCountryCodeChange}
                  />
                </div>
              </div>
              <div className="form-group my-2">
                <input
                  type="text"
                  className={`form-control px-3 ${
                      errors.companyName ? "is-invalid" : ""
                    }`}
                  id="clientPhone"
                  name="clientPhone"
                  placeholder="Phone Number"
                  {...register("clientPhone", { required: true })}
                />
                {errors.clientPhone && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>
            </div>
            <h6>Billing Address</h6>
            <div className="form-row">
              <div className="form-group">
                <div className="select-client-container">
                  <Select
                    id="clientCountry"
                    options={country}
                    placeholder={selectedCountry || "Select Country"}
                    {...register("clientCountry")}
                    isSearchable
                    className="select-client"
                    onChange={handleCountryChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="select-client-container">
                  <Select
                    id="clientState"
                    options={state}
                    placeholder={selectedState || "Select State"}
                    onChange={handleStateChange}
                    isSearchable
                    className="select-client"
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <div className="select-client-container">
                  <Select
                    id="clientCity"
                    options={city}
                    placeholder={selectedCity || "Select City"}
                    {...register("clientCity")}
                    onChange={handleCityChange}
                    isSearchable
                    className="select-client"
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control my-2 px-3 ${
                    errors.clientZip ? "is-invalid" : ""
                  }`}
                  id="clientZip"
                  name="clientZip"
                  placeholder="Enter PinCode"
                  {...register("clientZip", { required: true })}
                />
                {errors.clientZip && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control px-3 `}
                  id="clientPan"
                  name="clientPan"
                  placeholder="Enter PAN"
                 
                />
               
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control`}
                  id="clientGst"
                  name="clientGst"
                  placeholder="Enter GSTIN"
                 
                />
                
              </div>
            </div>
            <div className="form-group">
              <input
                type="text"
                className={`form-control px-3 `}
                id="clientWebsite"
                name="clientWebsite"
                placeholder="Enter Website"
                
              />
              
            </div>
            <div className="form-group">
              <input
                type="text"
                className={`form-control px-3 ${
                      errors.clientAddress ? "is-invalid" : ""
                    }`}
                id="clientAddress"
                name="clientAddress"
                placeholder="Enter Address"
                {...register("clientAddress", { required: true })}
              />
              {errors.clientAddress && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
            <div className="d-flex mt-2 justify-content-center">
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
);

};

export default ClientForm;
