import {createSlice} from '@reduxjs/toolkit';
import {getcountries, getstate , getcity, getcountryCode, fetchUserData } from '@Networking/APIs/Background/backgorundApi';

const BackgroundSlice = createSlice({
  name: 'BackgroundSlice',
  initialState: {
    country: [],
    state: [],
    city: [],
    countryCode:[],
    isLoading: false,
    error: null,
    userData:null
  },
  extraReducers: builder => {
    builder.addCase(fetchUserData.pending, state => {
      // console.log("Pending....");
      state.isLoading = true;
    }); 
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
        //  console.log("Fulfilled....");
      state.userData = action.payload;
      state.isLoading = false;
      console.log(state.userData," state.userData")
      console.log(state.error," state.error")
    });
    builder.addCase(fetchUserData.rejected, (state, action) => {
      // console.log("Rejected....");
      state.loading = false;
      state.error = action.error.message;
      console.log(state.error);
    });
    builder.addCase(getcountries.pending, state => {
      // console.log("Pending....");
      state.loading = true;
    });
    builder.addCase(getcountries.fulfilled, (state, action) => {
        //  console.log("Fulfilled....");
      state.country = action.payload;
      console.log(state.country," state.country")
    });
    builder.addCase(getcountries.rejected, state => {
      // console.log("Rejected....");
      state.loading = false;
    });

    builder.addCase(getstate.pending, state => {
        // console.log("Pending....");
        state.loading = true;
      });
      builder.addCase(getstate.fulfilled, (state, action) => {
          //  console.log("Fulfilled....");
        state.state = action.payload;
        console.log(state.state," state.Category")
      });
      builder.addCase(getstate.rejected, state => {
        // console.log("Rejected....");
        state.loading = false;
      });


      builder.addCase(getcity.pending, state => {
      // console.log("Pending....");
      state.loading = true;
    });
    builder.addCase(getcity.fulfilled, (state, action) => {
        //  console.log("Fulfilled....");
      state.city = action.payload;
      console.log(state.Category," state.Category")
    });
    builder.addCase(getcity.rejected, state => {
      // console.log("Rejected....");
      state.loading = false;
    });
    
    builder.addCase(getcountryCode.pending, state => {
        // console.log("Pending....");
        state.loading = true;
      });
      builder.addCase(getcountryCode.fulfilled, (state, action) => {
          //  console.log("Fulfilled....");
        state.countryCode = action.payload;
        console.log(state.Category," state.Category")
      });
      builder.addCase(getcountryCode.rejected, state => {
        // console.log("Rejected....");
        state.loading = false;
      });

  },
});

//Reducer
export default BackgroundSlice.reducer;