import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserData } from '@Networking/APIs/Background/backgorundApi';
import { toast } from 'react-toastify';
import Loader from "@Layout/Loader/loader";

export const ProtectedRoute = ({ allowedRoles }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { userData, error } = useSelector((state) => ({
    userData: state.BackgroundSlice.userData,
    error: state.BackgroundSlice.error,
  }));

  const { userLoginData } = useSelector((state) => state.LoginSlice);

  const localuserdata = userLoginData || userData;

  useEffect(() => {
    if (!userLoginData) {
      dispatch(fetchUserData());
    }
  }, [dispatch, userLoginData]);

  useEffect(() => {
    if (error) {
      if (error === 'Request failed with status code 401') {
        toast.error("Unauthorized access. Please log in.");
        navigate("/");
      }
    } else if (localuserdata) {
      const userRole = localuserdata.role_id;

      if (!userRole) {
        navigate("/");
      } else if (allowedRoles && !allowedRoles.includes(userRole)) {
        toast.error("You are not authorized to access this page.");
        navigate(-1);
      }
    }
  }, [localuserdata, error, allowedRoles, navigate]);

  if (localuserdata && location.pathname === '/') {
    return <Navigate to="/dashboard" />;
  }

  if (!localuserdata && !error) {
    return <div><Loader /></div>;
  }

  if (error === 'Request failed with status code 401') {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};
