import { CategoryStore, getCategory } from '@Networking/APIs/Category/CategoryGetApi';
import {createSlice} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const CategorySlice = createSlice({
  name: 'CategorySlice',
  initialState: {
    Category: '',
    CategoryGet:[],
  },
  extraReducers: builder => {
    builder.addCase(getCategory.pending, state => {
      // console.log("Pending....");
      state.loading = true;
    });
    builder.addCase(getCategory.fulfilled, (state, action) => {
        //  console.log("Fulfilled....");  
      state.CategoryGet = action.payload;
      // console.log(state.CategoryGet," state.Category")
    });
    builder.addCase(getCategory.rejected, state => {
      // console.log("Rejected....");
      state.loading = false;
    });
    builder.addCase(CategoryStore.pending, state => {
      // console.log("Pending....");
      state.loading = true;
    });
    builder.addCase(CategoryStore.fulfilled, (state, action) => {
      if(action.payload.status == true){
        toast.success(action.payload.message)
      }
      else{
        toast.error(action.payload.message)
        // console.log("this is error ");
      }
      // console.log(state.CategoryGet," state.Category")
    });
    builder.addCase(CategoryStore.rejected, state => {
      // console.log("Rejected....");
      state.loading = false;
    });
  },
});

//Reducer
export default CategorySlice.reducer;
