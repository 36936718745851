
export const BASE_URL = "https://developbo.techsimba.in/management/api/"

// Auth
export const SignUpUrl = "register";
export const LoginUrl = "login";
export const LogoutUrl = "logout";
export const Verifycode = "verifycode";

// Get User 
export const getuser = "user";

// clientS
export const client = "clients";
export const clientGet = "all/clients";

// ItemsStore
export const items = "items";
export const getitems = "getitems";

// categorySore
export const categories = "categories";
export const categoriesGet = "get/categories"

// banksStore
export const banks = "banks";
export const banksget = "direct/banks";
export const indirectBank = "indirect/banks"


//CompanyGet
export const companiesGet = "companies/all"
export const companies = "companies";

// Address
export const countries = "countries";
export const states = "states/";
export const cities = "cities/";
//country Code
export const countrycode = "countri/code";


//Invoice api
export const getinvoicenumber = "invoice/number";
export const storeinvoicenumber = "invoice/store";
export const allinvoice =  "allinvoice" ;
export const updateInvoice = "invoice/update";


//PDFVIEW
export const PDFData = "invoice/view";