import React from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

export const InvoicePartiallyForm = () => {
  const location = useLocation();
  const { invoicedata } = location.state || {};
  console.log(invoicedata, "invoicedata");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className="container mt-5">
      <div className="card shadow p-4">
        <h4 className="text-center mb-4">Invoice Partial Payment</h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="invoiceNumber" className="form-label">Invoice Number:</label>
              <input
                type="text"
                className="form-control"
                id="invoiceNumber"
                defaultValue={invoicedata?.Invoice_No}
                readOnly
                {...register("invoiceNumber")}
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="invoiceBy" className="form-label">Invoice By:</label>
              <input
                type="text"
                className={`form-control ${errors.invoiceBy ? "is-invalid" : ""}`}
                id="invoiceBy"
                defaultValue={invoicedata?.Company_Name}
                placeholder="Company Name"
                {...register("invoiceBy", { required: "Invoice by is required" })}
              />
              {errors.invoiceBy && <span className="text-danger small">{errors.invoiceBy.message}</span>}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="invoiceAmountUSD" className="form-label">Invoice Amount ($):</label>
              <input
                type="number"
                className={`form-control ${errors.invoiceAmountUSD ? "is-invalid" : ""}`}
                id="invoiceAmountUSD"
                {...register("invoiceAmountUSD", {
                  required: "Invoice amount is required",
                  min: { value: 0, message: "Amount must be positive" }
                })}
              />
              {errors.invoiceAmountUSD && <span className="text-danger small">{errors.invoiceAmountUSD.message}</span>}
            </div>

            <div className="col-md-6">
              <label htmlFor="exchangeRate" className="form-label">Exchange Rate:</label>
              <input
                type="number"
                className={`form-control ${errors.exchangeRate ? "is-invalid" : ""}`}
                id="exchangeRate"
                placeholder="1"
                {...register("exchangeRate", {
                  required: "Exchange rate is required",
                  min: { value: 0, message: "Rate must be positive" }
                })}
              />
              {errors.exchangeRate && <span className="text-danger small">{errors.exchangeRate.message}</span>}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="amountToBePaidUSD" className="form-label">Amount To Be Paid ($):</label>
              <input
                type="number"
                className={`form-control ${errors.amountToBePaidUSD ? "is-invalid" : ""}`}
                id="amountToBePaidUSD"
                {...register("amountToBePaidUSD", {
                  required: "Amount to be paid is required",
                  min: { value: 0, message: "Amount must be positive" }
                })}
              />
              {errors.amountToBePaidUSD && <span className="text-danger small">{errors.amountToBePaidUSD.message}</span>}
            </div>

            <div className="col-md-6">
              <label htmlFor="paidAmountUSD" className="form-label">Paid Amount ($):</label>
              <input
                type="number"
                className={`form-control ${errors.paidAmountUSD ? "is-invalid" : ""}`}
                id="paidAmountUSD"
                {...register("paidAmountUSD", {
                  required: "Paid amount is required",
                  min: { value: 0, message: "Amount must be positive" }
                })}
              />
              {errors.paidAmountUSD && <span className="text-danger small">{errors.paidAmountUSD.message}</span>}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="paidAmountINR" className="form-label">Paid Amount (INR):</label>
              <input
                type="number"
                className={`form-control ${errors.paidAmountINR ? "is-invalid" : ""}`}
                id="paidAmountINR"
                {...register("paidAmountINR", {
                  required: "Paid amount in INR is required",
                  min: { value: 0, message: "Amount must be positive" }
                })}
              />
              {errors.paidAmountINR && <span className="text-danger small">{errors.paidAmountINR.message}</span>}
            </div>

            <div className="col-md-6">
              <label htmlFor="paymentMode" className="form-label">Payment Mode:</label>
              <select
                className={`form-select ${errors.paymentMode ? "is-invalid" : ""}`}
                {...register("paymentMode", { required: "Payment mode is required" })}
                id="paymentMode"
              >
                <option value="">Select</option>
                <option value="Bank">Bank</option>
                <option value="Paypal">Paypal</option>
                <option value="Payoneer">Payoneer</option>
              </select>
              {errors.paymentMode && <span className="text-danger small">{errors.paymentMode.message}</span>}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="invoiceDate" className="form-label">Invoice Date:</label>
              <input
                type="date"
                className={`form-control ${errors.invoiceDate ? "is-invalid" : ""}`}
                {...register("invoiceDate", { required: "Invoice date is required" })}
                id="invoiceDate"
              />
              {errors.invoiceDate && <span className="text-danger small">{errors.invoiceDate.message}</span>}
            </div>

            <div className="col-md-6">
              <label htmlFor="paymentDate" className="form-label">Payment Date:</label>
              <input
                type="date"
                className={`form-control ${errors.paymentDate ? "is-invalid" : ""}`}
                {...register("paymentDate", { required: "Payment date is required" })}
                id="paymentDate"
              />
              {errors.paymentDate && <span className="text-danger small">{errors.paymentDate.message}</span>}
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="comment" className="form-label">Comment:</label>
            <textarea
              className="form-control"
              {...register("comment")}
              id="comment"
              rows="3"
              placeholder="Add any comments here..."
            ></textarea>
          </div>
          <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-primary">
                Add Invoice Payment
                </button>
              </div>
         
        </form>
      </div>
    </div>
  );
};
